import * as React from "react";
import {Helmet} from "react-helmet";

import {useConfig} from "gatsby-theme-advanced";

import Layout from "../layouts";
import {constants, H1} from "../theme";
import {PurposeImg1} from "../images/Image";
import PurposeInfo from "../components/Purpose/PurposeInfo";
import styled from "styled-components";
import Market from "../components/Purpose/Market";

import {FormattedMessage} from "gatsby-plugin-react-intl";


const H1Wrapper = styled.div`
width: 100%;
padding: 9rem 16px 4.5rem 16px;
justify-self: center;
max-width: calc(1144px + 2 * 16px);
margin:auto;

  @media (max-width: ${constants.breakpoints.lg}) {
  display:none;
  }
`

const PurposeImageWrapper = styled.div`
width:60%;

  @media (max-width: ${constants.breakpoints.lg}) {
  width:100%;
  }

`

const OurPurposePage = (): JSX.Element => {
    const config = useConfig();

    return (
        <Layout>
            <div className="our-purpose-container">
                <H1Wrapper><H1><FormattedMessage id={'purpose_6'}></FormattedMessage> </H1></H1Wrapper>
                <PurposeImageWrapper><PurposeImg1/></PurposeImageWrapper>
                <PurposeInfo/>
                <Market/>
                <Helmet title={`Our Purpose | ${config.website.title}`}/>
            </div>
        </Layout>
    );
};


export default OurPurposePage;
