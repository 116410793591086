
import React from "react";
import styled from "styled-components";

//import { Types } from "gatsby-theme-advanced";
import {constants} from "../../theme";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import {CommunityIcon, PropertyOwnerIcon} from "./Icons";



const Wrapper = styled.div`
padding-top:7rem;
display:flex;
justify-content:space-between;
width:50%;
margin:auto;
text-align:center;


  @media (max-width: ${constants.breakpoints.lg}) {
} 
  @media (max-width: ${constants.breakpoints.sm}) {
display:block;
padding-top:0rem;

} 


`;

const SupportContainer = styled.div`
width:50%;

  @media (max-width: ${constants.breakpoints.sm}) {
  width:100%;
  padding-top:4rem;
} 
`

const SupportIcon = styled.div`
svg{
width:47px;
height:47px;
}
`

const SupportCaption = styled.div`
font-family:"Madera";
font-size:1rem;
text-transform: uppercase;
padding-top:0.7rem;
`

const SupportDesription = styled.div`
font-family:"Madera Light";
font-size:0.8rem;
padding-top: 1.1rem;
`

class Support extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
    }
  }




  componentDidMount() {

  }

  render() {
    return(
<Wrapper>
    <SupportContainer>
<PropertyOwnerIcon/>
<SupportCaption><FormattedMessage id={'support_1'}/></SupportCaption>
        <SupportDesription><FormattedMessage id={'support_1_1'}/></SupportDesription>
    </SupportContainer>
    <SupportContainer>
<CommunityIcon/>
<SupportCaption><FormattedMessage id={'support_2'}/></SupportCaption>
        <SupportDesription><FormattedMessage id={'support_2_1'}/></SupportDesription>
    </SupportContainer>
  </Wrapper>

    )
  }
}


export default Support


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
