import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../layouts";
import {ContactImg1} from "../images/Image";
import Imprint from "../components/Imprint/Imprint";


const ImprintPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <div className="contact-container">
          <div><ContactImg1/></div>
          <Imprint/>
        <Helmet title={`Imprint | ${config.website.title}`} />
      </div>
    </Layout>
  );
};

export default ImprintPage;
