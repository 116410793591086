
import React from "react";
import styled from "styled-components";

//import { Types } from "gatsby-theme-advanced";
//import {H2, H3} from "../../theme";
//import { FormattedMessage } from "gatsby-plugin-react-intl";

const AccessibilityIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

     .cls-1, .cls-2 {
        fill: none;
      }

      .cls-1 {
        stroke: #2e2d2c;
        stroke-width: 6.41px;
      }

      .cls-1, .cls-2, .cls-3 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-2, .cls-3 {
        stroke: #fff;
        stroke-width: 21.64px;
      }

      .cls-3 {
        fill: #fff;
      }
`


const DigitizationIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {
        fill: none;
        stroke-linejoin: round;
      }

      .cls-1, .cls-3, .cls-5 {
        stroke: #2e2d2c;
        stroke-width: 6.41px
      }

      .cls-1, .cls-2 {
        stroke-linecap: round;
      }

      .cls-2, .cls-4 {
        stroke: #fff;
        stroke-width: 21.64px;
      }

      .cls-4, .cls-5 {
        stroke-linecap: square;
      }

`

const RenewImageIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

      .cls-1 {
        fill: none;
        stroke: #2e2d2c;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 6.41px
      }


`
const PropertyOwnerIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

     .cls-1, .cls-2 {
        fill: none;
      }

      .cls-1, .cls-3 {
        stroke: #2e2d2c;
        stroke-width: 6.41px;
      }

      .cls-1, .cls-2, .cls-3 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-2 {
        stroke: #fff;
        stroke-width: 21.64px;
      }

      .cls-3 {
        fill: #fff;
      }

`
const CommunityIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

      .cls-1 {
        fill: #2e2d2c;
        stroke-width:1px;
      }

      .cls-2 {
      
        fill: #fff;
        stroke: #fff;
        stroke-width: 21.64px;
      }

      .cls-2, .cls-3 {
        stroke-linecap: round;
        stroke-linejoin: round;
        
      }

      .cls-3 {
        fill: none;
        stroke: #2e2d2c;
        stroke-width: 6.41px;
      }


`
const DevelopmentIconContainer = styled.div`
svg{
width:47px;
height:47px;
}
      .cls-1, .cls-2 {
        fill: none;
        stroke: #2e2d2c;
        stroke-miterlimit: 10;
        stroke-width: 6.41px
      }

      .cls-2 {
        stroke-linecap: round;
      }


`
const ResearchIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

  .cls-1, .cls-2 {
        fill: none;
      }

      .cls-1 {
        stroke: #2e2d2c;
        stroke-width: 6.41px
      }

      .cls-1, .cls-2, .cls-3 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-2, .cls-3 {
        stroke: #fff;
      }

      .cls-2 {
        stroke-width: 21.7px;
      }

      .cls-3 {
        fill: #fff;
        stroke-width: 16.27px;
      }


`
const ConsultingIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

      .cls-1 {
        fill: #fff;
        stroke: #2e2d2c;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 6.41px
      }


`
const ConceptionIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

      .cls-1, .cls-2 {
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-1 {
        stroke: #2e2d2c;
        stroke-width: 6.41px
      }

      .cls-2 {
        stroke: #fff;
        stroke-width: 16.27px;
      }


`


const OperatingIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

    .cls-1, .cls-3 {
        fill: #fff;
      }

      .cls-1, .cls-2, .cls-4, .cls-5, .cls-7 {
        stroke: #2e2d2c;
        stroke-width: 6.41px
      }

      .cls-1, .cls-2 {
        stroke-miterlimit: 10;
      }

      .cls-2, .cls-4, .cls-5, .cls-6, .cls-7 {
        fill: none;
      }

      .cls-3, .cls-6 {
        stroke: #fff;
        stroke-width: 16.27px;
      }

      .cls-3, .cls-4, .cls-7 {
        stroke-linecap: round;
      }

      .cls-3, .cls-4 {
        stroke-linejoin: round;
      }

      .cls-5, .cls-6 {
        stroke-linecap: square;
      }

      .cls-5, .cls-6, .cls-7 {
        stroke-linejoin: bevel;
      }


`
const MaintenanceIconContainer = styled.div`
svg{
width:47px;
height:47px;
}

      .cls-1 {
        fill: none;
        stroke: #2e2d2c;
        stroke-width: 7.41px;
      } 

      .cls-1, .cls-2 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-2 {
        fill: #fff;
        stroke: #fff;
        stroke-width: 21.64px;
      }


`






export const MaintenanceIcon = () =>

    <MaintenanceIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227.68 191.48">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <g>
                            <polygon className="cls-1"
                                     points="107.75 19.12 118.16 8.71 157.77 8.71 225.48 76.42 195.27 106.63 107.75 19.12"/>
                            <path className="cls-1" d="M143.93,55.29l-100,100A16,16,0,0,0,66.57,178l100-100Z"/>
                        </g>
                        <g>
                            <path className="cls-2"
                                  d="M177.22,149.74,111.34,83.86a7.69,7.69,0,0,0-10.87,0l-2.06,2.06L58.88,46.39A12.31,12.31,0,0,1,55.3,38.6l-.53-6.86a8.9,8.9,0,0,0-4.62-7.14L21.31,8.82,8.82,21.31,24.6,50.16a8.91,8.91,0,0,0,7.14,4.61l6.86.53a12.31,12.31,0,0,1,7.79,3.58L85.92,98.41l-2.06,2.06a7.69,7.69,0,0,0,0,10.87l65.1,65.1c7.44,7.44,19.68,8.54,27.5,1.5A19.43,19.43,0,0,0,177.22,149.74Z"/>
                            <g>
                                <path className="cls-1"
                                      d="M100.47,83.86,83.86,100.47a7.69,7.69,0,0,0,0,10.87l65.1,65.1c7.44,7.44,19.68,8.54,27.5,1.5a19.43,19.43,0,0,0,.76-28.2L111.34,83.86A7.69,7.69,0,0,0,100.47,83.86Z"/>
                                <path className="cls-1"
                                      d="M50.16,24.6,21.31,8.82,8.82,21.31,24.6,50.16a8.91,8.91,0,0,0,7.14,4.61l6.86.53a12.31,12.31,0,0,1,7.79,3.58L85.92,98.41,98.41,85.92,58.88,46.39A12.31,12.31,0,0,1,55.3,38.6l-.53-6.86A8.91,8.91,0,0,0,50.16,24.6Z"/>
                            </g>
                            <line className="cls-1" x1="103.21" y1="103.21" x2="163.48" y2="163.48"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </MaintenanceIconContainer>




export const OperatingIcon = () =>

    <OperatingIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.26 179.86">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <g>
                            <path className="cls-1" d="M55.66,93.16a37.4,37.4,0,0,1,74.8,0"/>
                            <path className="cls-2"
                                  d="M155.31,126.06l5.06-12.17a5.45,5.45,0,0,1,3.59-3.21l17.24-4.79a3.49,3.49,0,0,0,2.62-3.25v-19a3.49,3.49,0,0,0-2.62-3.25L164,75.66a5.42,5.42,0,0,1-3.57-3.21l-5-12.19a5.5,5.5,0,0,1,.26-4.81l8.8-15.59a3.49,3.49,0,0,0-.45-4.15L150.6,22.34a3.52,3.52,0,0,0-4.16-.45l-15.58,8.8a5.44,5.44,0,0,1-4.8.26l-12.17-5.06a5.41,5.41,0,0,1-3.21-3.58L105.89,5.06a3.49,3.49,0,0,0-3.25-2.62h-19a3.5,3.5,0,0,0-3.25,2.62L75.66,22.3a5.47,5.47,0,0,1-3.22,3.58l-12.18,5a5.47,5.47,0,0,1-4.81-.26l-15.59-8.8a3.5,3.5,0,0,0-4.15.45L22.34,35.67a3.5,3.5,0,0,0-.45,4.15l8.8,15.58A5.44,5.44,0,0,1,31,60.2L25.89,72.38a5.45,5.45,0,0,1-3.58,3.2L5.06,80.37a3.49,3.49,0,0,0-2.62,3.25v18.95a3.49,3.49,0,0,0,2.62,3.25l17.24,4.79a5.44,5.44,0,0,1,3.58,3.21l5,12.18"/>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <circle className="cls-3" cx="49.52" cy="93.16" r="14.36"/>
                                    <path className="cls-3" d="M73.15,119.71H25.9a11.64,11.64,0,0,0-11.65,11.64v40.38"/>
                                </g>
                                <g>
                                    <circle className="cls-4" cx="49.52" cy="93.16" r="14.36"/>
                                    <path className="cls-5" d="M73.15,119.71H25.9a11.64,11.64,0,0,0-11.65,11.64v40.38"/>
                                    <line className="cls-5" x1="32.21" y1="153.9" x2="32.21" y2="171.73"/>
                                </g>
                                <g>
                                    <circle className="cls-3" cx="136.74" cy="93.16" r="14.36"/>
                                    <path className="cls-6"
                                          d="M172,171.73V131.35a11.64,11.64,0,0,0-11.64-11.64H113.11"/>
                                </g>
                                <g>
                                    <circle className="cls-4" cx="136.74" cy="93.16" r="14.36"/>
                                    <path className="cls-5"
                                          d="M172,171.73V131.35a11.64,11.64,0,0,0-11.64-11.64H113.11"/>
                                    <line className="cls-5" x1="154.05" y1="171.73" x2="154.05" y2="153.9"/>
                                    <line className="cls-7" x1="32.21" y1="146.75" x2="32.21" y2="159.15"/>
                                    <line className="cls-7" x1="154.05" y1="159.15" x2="154.05" y2="146.75"/>
                                </g>
                            </g>
                            <circle className="cls-3" cx="93.13" cy="119.71" r="14.36"/>
                            <g>
                                <circle className="cls-4" cx="93.13" cy="119.71" r="14.36"/>
                                <path className="cls-5"
                                      d="M128.4,171.73V157.89a11.64,11.64,0,0,0-11.64-11.64H69.51a11.64,11.64,0,0,0-11.65,11.64v13.84"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </OperatingIconContainer>




export const ConceptionIcon = () =>

    <ConceptionIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.47 195.09">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <g>
                            <line className="cls-1" x1="2.44" y1="50.62" x2="19.64" y2="65.48"/>
                            <line className="cls-1" x1="44.72" y1="13.22" x2="54.71" y2="40.61"/>
                            <line className="cls-1" x1="96.24" y1="2.44" x2="96.24" y2="40.61"/>
                        </g>
                        <g>
                            <line className="cls-1" x1="190.03" y1="50.62" x2="172.83" y2="65.48"/>
                            <line className="cls-1" x1="147.75" y1="13.22" x2="137.76" y2="40.61"/>
                        </g>
                        <g>
                            <g>
                                <polygon className="cls-1" points="63.09 99.39 96.23 60.49 129.38 99.39 63.09 99.39"/>
                                <polygon className="cls-1" points="63.09 99.39 96.24 186.96 129.38 99.39 63.09 99.39"/>
                                <polygon className="cls-1"
                                         points="129.39 99.39 142.23 60.49 175.72 99.39 129.39 99.39"/>
                                <polygon className="cls-1" points="16.61 99.39 50.22 60.49 63.06 99.39 16.61 99.39"/>
                                <polygon className="cls-1" points="16.61 99.39 96.24 186.96 63.06 99.39 16.61 99.39"/>
                                <polygon className="cls-1" points="96.23 60.49 63.09 99.39 50.22 60.49 96.23 60.49"/>
                                <polygon className="cls-1"
                                         points="175.72 99.39 96.24 186.96 129.38 99.39 175.72 99.39"/>
                                <polygon className="cls-1" points="96.23 60.49 129.39 99.39 142.23 60.49 96.23 60.49"/>
                            </g>
                            <polygon className="cls-2"
                                     points="142.23 60.49 50.22 60.49 16.61 99.39 96.24 186.96 175.72 99.39 142.23 60.49"/>
                            <line className="cls-2" x1="16.61" y1="99.39" x2="175.72" y2="99.39"/>
                            <line className="cls-1" x1="16.61" y1="99.39" x2="175.72" y2="99.39"/>
                            <polygon className="cls-1"
                                     points="142.23 60.49 50.22 60.49 16.61 99.39 96.24 186.96 175.72 99.39 142.23 60.49"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </ConceptionIconContainer>


export const ConsultingIcon = () =>

    <ConsultingIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.12 180.17">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <path className="cls-1"
                              d="M63.5,15.82A13.38,13.38,0,1,1,50.12,2.44,13.38,13.38,0,0,1,63.5,15.82Z"/>
                        <path className="cls-1"
                              d="M145.69,15.82A13.38,13.38,0,1,1,132.31,2.44,13.38,13.38,0,0,1,145.69,15.82Z"/>
                        <g>
                            <path className="cls-1"
                                  d="M76.79,56.4V50.33A10.32,10.32,0,0,0,66.48,40H33.76A10.32,10.32,0,0,0,23.45,50.33v23.4"/>
                            <path className="cls-1"
                                  d="M159,73.32v-23A10.31,10.31,0,0,0,148.67,40H116a10.32,10.32,0,0,0-10.31,10.31v6"/>
                        </g>
                        <path className="cls-1"
                              d="M180.68,123.45c0-30-39.9-54.27-89.12-54.27S2.44,93.48,2.44,123.45c0,13.33,7.92,25.52,21,35V145.19a10.31,10.31,0,0,1,10.31-10.31H66.48a10.31,10.31,0,0,1,10.31,10.31v31.74a145.44,145.44,0,0,0,14.77.8c4.81,0,9.49-.29,14.08-.74v-31.8A10.31,10.31,0,0,1,116,134.88h32.72A10.3,10.3,0,0,1,159,145.19v13.69C172.47,149.37,180.68,137,180.68,123.45Zm-130.56.61A13.38,13.38,0,1,1,63.5,110.68,13.38,13.38,0,0,1,50.12,124.06Zm82.19,0a13.38,13.38,0,1,1,13.38-13.38A13.38,13.38,0,0,1,132.31,124.06Z"/>
                    </g>
                </g>
            </g>
        </svg>

    </ConsultingIconContainer>


export const ResearchIcon = () =>

    <ResearchIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.79 188.27">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <g>
                            <line className="cls-1" x1="10.97" y1="130.68" x2="10.97" y2="100.8"/>
                            <line className="cls-1" x1="29.26" y1="130.68" x2="29.26" y2="82.38"/>
                            <line className="cls-1" x1="47.56" y1="130.68" x2="47.56" y2="64.07"/>
                            <line className="cls-1" x1="65.86" y1="130.68" x2="65.86" y2="45.68"/>
                            <line className="cls-1" x1="84.16" y1="130.68" x2="84.16" y2="64.1"/>
                            <line className="cls-1" x1="102.46" y1="130.68" x2="102.46" y2="45.53"/>
                            <line className="cls-1" x1="120.75" y1="27.22" x2="120.75" y2="130.68"/>
                        </g>
                        <polyline className="cls-2" points="10.85 100.8 65.93 45.68 84.12 63.87 137.12 10.85"/>
                        <polyline className="cls-1" points="10.85 100.8 65.93 45.68 84.12 63.87 127.09 20.88"/>
                        <polyline className="cls-1" points="116.07 10.53 137.12 10.53 137.12 31.58"/>
                        <g>
                            <path className="cls-3"
                                  d="M158.13,128.38c0,21.44-17,38.82-37.87,38.82s-37.88-17.38-37.88-38.82,17-38.81,37.88-38.81S158.13,106.94,158.13,128.38Z"/>
                            <path className="cls-1"
                                  d="M158.13,128.38c0,21.44-17,38.82-37.87,38.82s-37.88-17.38-37.88-38.82,17-38.81,37.88-38.81S158.13,106.94,158.13,128.38Z"/>
                            <path className="cls-1" d="M95.41,128.38c0-14.06,11.12-25.46,24.85-25.46"/>
                            <path className="cls-1"
                                  d="M140.89,161.37l21.46,22a8,8,0,0,0,11.59,0,8.53,8.53,0,0,0,0-11.88l-21.46-22"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </ResearchIconContainer>



export const DevelopmentIcon = () =>

    <DevelopmentIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.86 180.17">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <g>
                            <path className="cls-1"
                                  d="M117,96.86a2.81,2.81,0,0,0,.56-3.36l-8.79-15.22a2.79,2.79,0,0,0-3.18-1.19l-14.74,4.8A4.9,4.9,0,0,1,87,81.33l-9.7-5.61a5,5,0,0,1-2.45-3.12L71.57,57.43a2.8,2.8,0,0,0-2.63-2.16H51.43a2.8,2.8,0,0,0-2.63,2.16L45.56,72.6a5,5,0,0,1-2.45,3.12L33.4,81.33a5,5,0,0,1-3.92.56L14.73,77.1a2.79,2.79,0,0,0-3.18,1.2L2.78,93.48a2.8,2.8,0,0,0,.55,3.36l11.52,10.37a4.94,4.94,0,0,1,1.48,3.68v11.22a4.88,4.88,0,0,1-1.48,3.67L3.33,136.15a2.8,2.8,0,0,0-.56,3.35l8.79,15.22a2.81,2.81,0,0,0,3.19,1.2l14.73-4.81a5,5,0,0,1,3.92.56l9.71,5.61a5,5,0,0,1,2.45,3.12l3.24,15.17a2.8,2.8,0,0,0,2.63,2.16H68.94a2.81,2.81,0,0,0,2.63-2.16L74.8,160.4a4.91,4.91,0,0,1,2.45-3.11L87,151.68a4.89,4.89,0,0,1,3.93-.56l14.74,4.79a2.81,2.81,0,0,0,3.19-1.2l8.78-15.19a2.8,2.8,0,0,0-.56-3.35l-11.53-10.38a5,5,0,0,1-1.48-3.68V110.9a4.94,4.94,0,0,1,1.48-3.68Z"/>
                            <path className="cls-2" d="M82.66,116.2A22.47,22.47,0,1,1,60.19,93.73"/>
                        </g>
                        <g>
                            <path className="cls-1"
                                  d="M219.51,63.79a2.47,2.47,0,0,0,1.91-2.33V45.86a2.49,2.49,0,0,0-1.92-2.33l-14.62-2.45A4.24,4.24,0,0,1,202.2,39l-4.83-8.36a4.23,4.23,0,0,1-.49-3.38L202,13.32A2.48,2.48,0,0,0,201,10.49L187.5,2.73a2.5,2.5,0,0,0-3,.5l-9.45,11.42a4.28,4.28,0,0,1-3.17,1.27h-9.66a4.21,4.21,0,0,1-3.17-1.27L149.62,3.24a2.5,2.5,0,0,0-3-.5l-13.48,7.79a2.47,2.47,0,0,0-1.06,2.82l5.17,13.9a4.27,4.27,0,0,1-.48,3.38L132,39a4.24,4.24,0,0,1-2.68,2.11l-14.62,2.47a2.46,2.46,0,0,0-1.92,2.33v15.6a2.48,2.48,0,0,0,1.92,2.33l14.61,2.45A4.25,4.25,0,0,1,132,68.4l4.82,8.36a4.27,4.27,0,0,1,.49,3.38l-5.15,13.91a2.46,2.46,0,0,0,1.06,2.82l13.47,7.76a2.46,2.46,0,0,0,3-.49l9.46-11.42a4.27,4.27,0,0,1,3.17-1.27h9.66a4.24,4.24,0,0,1,3.16,1.27l9.46,11.42a2.5,2.5,0,0,0,3,.5L201,96.84A2.48,2.48,0,0,0,202.07,94L196.9,80.12a4.27,4.27,0,0,1,.48-3.38l4.82-8.37a4.32,4.32,0,0,1,2.69-2.11Z"/>
                            <path className="cls-2" d="M186.43,53.71a19.36,19.36,0,1,1-19.36-19.36"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </DevelopmentIconContainer>


export const CommunityIcon = () =>

    <CommunityIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.8 189.29">
            <g id="Layer_2" data-name="Layer 2">
                <g id="COMMUNITY_WORK" data-name="COMMUNITY WORK">
                    <g>
                        <g>
                            <path className="cls-1"
                                  d="M131.6,13.8a5,5,0,0,1,2.93.95,5,5,0,0,1,1.11,7L108.31,59.36c-3.41,4.69-8.29,7.38-13.41,7.38s-10-2.69-13.4-7.38L54.16,21.74A4.92,4.92,0,0,1,53.27,18a4.86,4.86,0,0,1,2-3.26,5,5,0,0,1,7,1.1L72.62,30.11a5.17,5.17,0,0,0,.42.51,26.93,26.93,0,0,0,13.45,9.74,26.35,26.35,0,0,0,8.21,1.29,27.5,27.5,0,0,0,22.13-11l10.71-14.75a5,5,0,0,1,4.06-2.06m0-6.08a11.08,11.08,0,0,0-9,4.56L111.91,27A21.52,21.52,0,0,1,94.7,35.56a20.46,20.46,0,0,1-6.33-1A20.93,20.93,0,0,1,77.78,26.8a1.55,1.55,0,0,0-.24-.27L67.19,12.28a11.09,11.09,0,0,0-17.95,13L76.58,62.94c4.56,6.28,11.24,9.88,18.32,9.88s13.77-3.6,18.33-9.88l27.34-37.62a11.11,11.11,0,0,0-2.46-15.49,11,11,0,0,0-6.51-2.11Z"/>
                            <path className="cls-1"
                                  d="M94.9,6.09a8.57,8.57,0,0,1,2.5.38l.09,0A8.38,8.38,0,0,1,94.9,22.86a8.2,8.2,0,0,1-2.59-.41A8.39,8.39,0,0,1,94.9,6.09M94.9,0A14.48,14.48,0,0,0,82,21.05a14.34,14.34,0,0,0,8.42,7.19A14.47,14.47,0,0,0,99.38.71h0A14.51,14.51,0,0,0,94.9,0Z"/>
                            <path className="cls-1"
                                  d="M33.76,31.56a5,5,0,0,1,4.06,2.06L65.15,71.23c3.41,4.69,4.45,10.17,2.87,15s-5.64,8.67-11.15,10.46L12.64,111.11a5,5,0,0,1-6.3-3.22,5,5,0,0,1,3.21-6.3l16.74-5.44c.22-.07.43-.16.64-.25a27,27,0,0,0,13.41-9.79,27.57,27.57,0,0,0,3.87-7.58,27.33,27.33,0,0,0-3.77-24.28L29.73,39.5a5,5,0,0,1,1.1-7,5,5,0,0,1,2.93-.95m-6.24,64h0m6.24-70.12a11.09,11.09,0,0,0-9,17.61L35.52,57.82a21.22,21.22,0,0,1,2.9,18.83,21.45,21.45,0,0,1-3,5.89,21,21,0,0,1-10.67,7.67,1.24,1.24,0,0,0-.33.15L7.67,95.8a11.09,11.09,0,1,0,6.86,21.09l44.22-14.37c7.38-2.4,12.87-7.64,15.06-14.37s.83-14.21-3.73-20.49L42.75,30a11.11,11.11,0,0,0-9-4.57Z"/>
                            <path className="cls-1"
                                  d="M18.34,61.73a8.28,8.28,0,0,1,4.91,1.6,8.38,8.38,0,0,1,3.05,9.38A8.06,8.06,0,0,1,25.1,75a8.4,8.4,0,0,1-6.8,3.46,8.39,8.39,0,0,1-8-11,8.41,8.41,0,0,1,8-5.78m0-6.08A14.47,14.47,0,1,0,30,78.62a14.63,14.63,0,0,0,2.06-4A14.46,14.46,0,0,0,18.34,55.65Z"/>
                            <path className="cls-1"
                                  d="M69.1,115a15.55,15.55,0,0,1,9.2,2.89c4.13,3,6.5,8,6.5,13.84v46.5a5,5,0,1,1-10,0V160.59a5,5,0,0,0,0-.67,27.08,27.08,0,0,0-11.18-21.8,27.52,27.52,0,0,0-16-5.2,27.08,27.08,0,0,0-8.22,1.28L22,139.83a4.78,4.78,0,0,1-1.54.25,5,5,0,0,1-1.55-9.77l44.23-14.37a19.33,19.33,0,0,1,6-1m0-6.09a25.29,25.29,0,0,0-7.86,1.28L17,124.52a11.09,11.09,0,1,0,6.86,21.1L41.2,140a20.86,20.86,0,0,1,6.34-1A21.24,21.24,0,0,1,60,143a21.09,21.09,0,0,1,8.68,17.2,1.38,1.38,0,0,0,0,.35v17.62a11.09,11.09,0,0,0,22.18,0V131.7c0-7.76-3.28-14.6-9-18.76a21.58,21.58,0,0,0-12.78-4.06Z"/>
                            <path className="cls-1"
                                  d="M47.59,151.74a8.35,8.35,0,0,1,4.91,1.6,8.51,8.51,0,0,1,1.87,1.87,8.39,8.39,0,1,1-13.57,9.86l0,0a8.4,8.4,0,0,1,6.83-13.28m0-6.09a14.49,14.49,0,0,0-11.72,23h0a14.48,14.48,0,1,0,23.42-17,14.53,14.53,0,0,0-11.7-6Z"/>
                            <path className="cls-1"
                                  d="M120.71,115a19.34,19.34,0,0,1,6,1l44.23,14.36a5,5,0,0,1,3.21,6.31,5,5,0,0,1-4.74,3.47,5,5,0,0,1-1.57-.26l-16.74-5.44a5.48,5.48,0,0,0-.67-.17,27,27,0,0,0-16.59,0A27.23,27.23,0,0,0,115,160V178.2a5,5,0,0,1-10,0V131.7c0-5.79,2.38-10.84,6.52-13.84a15.5,15.5,0,0,1,9.19-2.89m0-6.09a21.53,21.53,0,0,0-12.77,4.06c-5.74,4.16-9,11-9,18.76v46.51a11.09,11.09,0,0,0,22.18,0V160A21.11,21.11,0,0,1,135.71,140a20.89,20.89,0,0,1,6.44-1,21.11,21.11,0,0,1,6.69,1.09,1.53,1.53,0,0,0,.36.07l16.74,5.44a11.09,11.09,0,0,0,6.86-21.1l-44.22-14.36a25.4,25.4,0,0,0-7.87-1.28Z"/>
                            <path className="cls-1"
                                  d="M142.24,151.75a8.39,8.39,0,0,1,2.7,16.33l-.12,0a8.51,8.51,0,0,1-2.6.41,8.4,8.4,0,0,1-4.92-15.18,8.67,8.67,0,0,1,2.35-1.19,8.5,8.5,0,0,1,2.59-.41m0-6.09a14.43,14.43,0,0,0-8.51,2.77,14.48,14.48,0,0,0,13,25.48h0a14.48,14.48,0,0,0-4.48-28.25Z"/>
                            <path className="cls-1"
                                  d="M156.05,31.56a4.92,4.92,0,0,1,2.92.95,5,5,0,0,1,1.11,7L149.74,53.74c-.14.19-.27.39-.39.59a27.14,27.14,0,0,0-3.75,24.2A27.3,27.3,0,0,0,162.92,96l17.32,5.64a5,5,0,0,1,3.26,6.22l0,.09a5,5,0,0,1-6.31,3.21L132.93,96.74c-5.51-1.79-9.57-5.61-11.15-10.47s-.53-10.34,2.87-15L152,33.62a5,5,0,0,1,4.07-2.06m0-6.08a11.08,11.08,0,0,0-9,4.56L119.73,67.66C115.17,74,113.81,81.41,116,88.15s7.68,12,15.06,14.38l44.23,14.37a11,11,0,0,0,3.42.54,11.11,11.11,0,0,0,10.56-7.67h0a11.12,11.12,0,0,0-7.13-14L164.8,90.17a21.11,21.11,0,0,1-14.45-20,21,21,0,0,1,4.13-12.48,2,2,0,0,0,.18-.32L165,43.08a11.11,11.11,0,0,0-2.46-15.49,11,11,0,0,0-6.5-2.11Z"/>
                            <path className="cls-1"
                                  d="M171.48,61.73a8.39,8.39,0,1,1-8,11,8.65,8.65,0,0,1-.4-2.59,8.39,8.39,0,0,1,8.38-8.39m0-6.09A14.49,14.49,0,0,0,157,70.11a14.67,14.67,0,0,0,.7,4.48,14.45,14.45,0,0,0,13.77,10h0A14.48,14.48,0,0,0,186,70.11h0a14.48,14.48,0,0,0-14.48-14.47Z"/>
                        </g>
                        <g>
                            <path className="cls-2"
                                  d="M94.47,140c.24-.21,36.59-33.53,41-44.55a21.56,21.56,0,1,0-40-16,16.87,16.87,0,0,0-.53,1.68,13.37,13.37,0,0,0-.45-1.46A21.56,21.56,0,1,0,54.19,95C58.44,106.16,94.47,140,94.47,140Z"/>
                            <path className="cls-3"
                                  d="M94.47,140c.24-.21,36.59-33.53,41-44.55a21.56,21.56,0,1,0-40-16,16.87,16.87,0,0,0-.53,1.68,13.37,13.37,0,0,0-.45-1.46A21.56,21.56,0,1,0,54.19,95C58.44,106.16,94.47,140,94.47,140Z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </CommunityIconContainer>


export const PropertyOwnerIcon = () =>

    <PropertyOwnerIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.11 198.93">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <g>
                            <polyline className="cls-1" points="42.36 39.71 42.36 15.84 24.52 15.84 24.52 59.45"/>
                            <line className="cls-1" x1="75.15" y1="8.82" x2="8.82" y2="75.15"/>
                            <line className="cls-2" x1="75.15" y1="8.82" x2="8.82" y2="75.15"/>
                            <polyline className="cls-1" points="122.68 56.36 75.15 8.82 8.82 75.15"/>
                            <polyline className="cls-1"
                                      points="90.03 141.47 90.03 92.5 60.2 92.5 60.2 141.47 24.52 141.47 24.52 75.15"/>
                        </g>
                        <g>
                            <path className="cls-1"
                                  d="M177.68,135.72c0,10.54-11.2,24.54-25,24.54s-25-14-25-24.54l25-24.26Z"/>
                            <g>
                                <g>
                                    <line className="cls-1" x1="185.83" y1="175.41" x2="203.2" y2="175.41"/>
                                    <g>
                                        <path className="cls-1"
                                              d="M223.91,196.73l-2.77-39.21c0-7.73-5.36-15.26-13.12-16.5l-30.34-5.3"/>
                                        <path className="cls-2" d="M97.31,141c-7.75,1.24-13.12,8-13.12,16.5"/>
                                        <path className="cls-1"
                                              d="M127.65,135.72,97.31,141c-7.75,1.24-13.12,8-13.12,16.5l-2.76,39.21"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="cls-2" d="M113.45,82.43C113.45,60.09,131,42,152.67,42"/>
                                        <path className="cls-1"
                                              d="M113.48,137.92l0-55.49C113.45,60.09,131,42,152.67,42s39.21,18.11,39.21,40.45v55.49"/>
                                    </g>
                                    <path className="cls-3"
                                          d="M180,86.68c0,24.35-12.25,44.08-27.37,44.08S125.29,111,125.29,86.68"/>
                                    <path className="cls-1"
                                          d="M180,86.68a42.84,42.84,0,0,1-11,1.43c-15.55,0-28.83-8.16-34-19.65"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </PropertyOwnerIconContainer>

export const RenewImageIcon = () =>

    <RenewImageIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.4 204.4">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <path className="cls-1"
                              d="M200.39,78.6l-65.77,65.76A15.64,15.64,0,0,1,123.55,149H93a13.26,13.26,0,0,0-10.19,4.79l-31,37.35h0C40.91,204.67,22.37,206,10.4,194S-.27,163.49,13.32,152.6h0l37.35-31a13.23,13.23,0,0,0,4.78-10.19V80.85A15.69,15.69,0,0,1,60,69.78L125.81,4a6.18,6.18,0,0,1,8.75,0l65.83,65.83A6.2,6.2,0,0,1,200.39,78.6Z"/>
                        <line className="cls-1" x1="64.05" y1="65.77" x2="138.63" y2="140.35"/>
                        <line className="cls-1" x1="78.19" y1="51.63" x2="152.77" y2="126.21"/>
                        <path className="cls-1" d="M38.55,179.4a9.48,9.48,0,1,1,0-13.4A9.47,9.47,0,0,1,38.55,179.4Z"/>
                        <g>
                            <line className="cls-1" x1="145.1" y1="14.56" x2="105.78" y2="53.88"/>
                            <line className="cls-1" x1="160.01" y1="29.47" x2="127.76" y2="61.72"/>
                            <line className="cls-1" x1="174.93" y1="44.39" x2="135.61" y2="83.71"/>
                            <line className="cls-1" x1="189.84" y1="59.31" x2="157.6" y2="91.56"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </RenewImageIconContainer>

export const DigitizationIcon = () =>

    <DigitizationIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201.72 174.5">a
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <rect className="cls-1" x="87.78" y="141.3" width="26.16" height="30.99"/>
                        <line className="cls-2" x1="135.2" y1="141.3" x2="66.52" y2="141.3"/>
                        <line className="cls-1" x1="135.2" y1="172.29" x2="66.52" y2="172.29"/>
                        <g>
                            <rect className="cls-1" x="2.2" y="2.2" width="197.31" height="139.1" rx="13.33"/>
                            <line className="cls-1" x1="199.51" y1="122.08" x2="2.2" y2="122.08"/>
                        </g>
                        <g>
                            <polyline className="cls-1" points="131 52.77 131 101.73 70.75 101.73 70.75 52.67"/>
                            <polyline className="cls-2" points="58.27 65.15 100.87 22.55 143.48 65.15"/>
                            <polyline className="cls-1" points="58.27 65.15 100.87 22.55 143.48 65.15"/>
                            <line className="cls-3" x1="70.75" y1="29.02" x2="70.75" y2="52.67"/>
                            <line className="cls-4" x1="100.87" y1="76.14" x2="100.87" y2="101.73"/>
                            <line className="cls-5" x1="100.87" y1="76.14" x2="100.87" y2="101.73"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </DigitizationIconContainer>

export const AccessibilityIcon = () =>

    <AccessibilityIconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 229.93 182.78">
            <g id="Layer_2" data-name="Layer 2">
                <g id="icons">
                    <g>
                        <path className="cls-1" d="M227.72,51.21c0,8.36-14.42,15.14-32.21,15.14"/>
                        <path className="cls-2"
                              d="M58.19,174H80.45l7.22-7.22v-12.8a2.55,2.55,0,0,1,2.55-2.55H103v-12.8a2.55,2.55,0,0,1,2.55-2.55h12.81V123.23a2.55,2.55,0,0,1,2.55-2.55h12.8l9.52-9.52A42.4,42.4,0,1,0,121,88.9l-62.8,62.8Z"/>
                        <path className="cls-1"
                              d="M58.19,174H80.45l7.22-7.22v-12.8a2.55,2.55,0,0,1,2.55-2.55H103v-12.8a2.55,2.55,0,0,1,2.55-2.55h12.81V123.23a2.55,2.55,0,0,1,2.55-2.55h12.8l9.52-9.52A42.4,42.4,0,1,0,121,88.9l-62.8,62.8Z"/>
                        <path className="cls-3"
                              d="M194.82,46.28a42.39,42.39,0,0,0-81.46-10.81H24.56L8.82,51.21,24.56,67H34.77l9-9.05a2.57,2.57,0,0,1,3.61,0l9,9.05,9.05-9.05a2.55,2.55,0,0,1,3.6,0L78.19,67l9-9.05a2.57,2.57,0,0,1,3.61,0L99.9,67h13.46a42.39,42.39,0,0,0,81.46-20.67Z"/>
                        <path className="cls-1" d="M174,62.47c-6.55-2.77-10.67-6.79-10.67-11.26"/>
                        <path className="cls-1"
                              d="M194.82,46.28a42.39,42.39,0,0,0-81.46-10.81H24.56L8.82,51.21,24.56,67H34.77l9-9.05a2.57,2.57,0,0,1,3.61,0l9,9.05,9.05-9.05a2.55,2.55,0,0,1,3.6,0L78.19,67l9-9.05a2.57,2.57,0,0,1,3.61,0L99.9,67h13.46a42.39,42.39,0,0,0,81.46-20.67ZM163.65,66.52A15.31,15.31,0,1,1,179,51.21,15.3,15.3,0,0,1,163.65,66.52Z"/>
                        <path className="cls-2" d="M163.3,51.21c0-8.35,14.42-15.13,32.21-15.13,9.93,0,18.82,2.11,24.73,5.43"/>
                        <path className="cls-1" d="M163.3,51.21c0-8.35,14.42-15.13,32.21-15.13s32.21,6.78,32.21,15.13"/>
                        <path className="cls-1" d="M174,62.47c-6.55-2.77-10.67-6.79-10.67-11.26"/>
                        <path className="cls-2" d="M175.67,60.68a15.31,15.31,0,0,1-23,1.2"/>
                        <path className="cls-1" d="M179,51.21a15.31,15.31,0,1,1-30.61,0"/>
                    </g>
                </g>
            </g>
        </svg>
    </AccessibilityIconContainer>
