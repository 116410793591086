import React from "react";
import styled from "styled-components";
//import {useIntl, changeLocale, FormattedMessage } from "gatsby-plugin-react-intl";
//import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
//import 'react-vertical-timeline-component/style.min.css';
import { H3, H4} from "../../theme";

    import {FormattedMessage} from "gatsby-plugin-react-intl";




const ImprintWrap = styled.div`
font-family:"Madera";
width: 100%;
padding: 4.4rem 16px 5rem 16px;
justify-self: center;
max-width: 644px;
margin:auto;
`

const ImprintTitle = styled(H3)`
padding-bottom:1.7rem;
`
const ImprintParagraph = styled(H4)`
padding-bottom:1.77rem;
font-size:1.27rem;
`

const ImprintSection = styled.div`
`

const Email = styled.a`
padding-left:0.2rem;
text-decoration:none;
color:black;
transition:all .2s ease;
cursor:pointer;
:hover{
color:var(--color-primary);
}
`

const Address = styled.div`
padding-top:0.3rem;
padding-bottom:0.7rem;
`

const Managing = styled.div`
padding-top:0.7rem;
`
const Directors = styled.div`
padding-left:0.1rem;
`

const _7Arte = styled.div`
font-family:'Madera ExtraBold';
padding-right:0.3rem;
`

const Group = styled.div`
display:flex;
`



export default class Imprint extends React.Component {
    constructor(props: any) {
        super(props)
        this.state = {

        }
    }



    render() {

    return (
        <ImprintWrap>
<ImprintTitle><FormattedMessage id={'imprint_0'}/></ImprintTitle>
<ImprintParagraph><FormattedMessage id={'imprint_§'}/></ImprintParagraph>
<ImprintSection>
    <Group> <_7Arte><FormattedMessage id={'imprint_1_1'}/></_7Arte><FormattedMessage id={'imprint_1_2'}/> </Group>

    <br/>
    <FormattedMessage id={'imprint_2'}/>
    <Address>
    <FormattedMessage id={'imprint_3'}/><br/>
    <FormattedMessage id={'imprint_4'}/><br/>
    <FormattedMessage id={'imprint_5'}/>
    </Address>
    <FormattedMessage id={'imprint_6'}/><br/>
    <FormattedMessage id={'imprint_7'}/><Email href={'mailto:info@7artegroup.com'}>info@7artegroup.com</Email><br/><br/>
    <FormattedMessage id={'imprint_8'}/><br/>
    <FormattedMessage id={'imprint_9'}/><br/>
   <Managing> <FormattedMessage id={'imprint_10'}/><br/>
    <Directors><FormattedMessage id={'imprint_11'}/></Directors></Managing><br/>
</ImprintSection>
        </ImprintWrap>
    );
  }
}
