
import React from "react";
import styled from "styled-components";

//import { Types } from "gatsby-theme-advanced";
import {constants, H3} from "../../theme";
import { FormattedMessage } from "gatsby-plugin-react-intl";


const Wrapper = styled.div`
font-family:"Madera Light";
background:white;
  width: 60%;
  min-height:40vh;
  position:absolute;
  top:52vh;
  right:0%;
  padding:4.4rem 7rem;
  justify-items: center;
  
    @media (max-width: ${constants.breakpoints.lg}) {
    position:static;
    width:100%;
    padding:4rem 1rem;

    
  }
`;

const Bold = styled.div`
font-family:"Madera";
font-weight:bold;
`

class PurposeInfo extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
    }
  }




  componentDidMount() {

  }

  render() {
    return(
<Wrapper>
    <div>
 <H3><FormattedMessage id={'purpose_1'}/></H3>
        <br/>
 <Bold><FormattedMessage id={'purpose_2'}/></Bold>
 <div><FormattedMessage id={'purpose_3'}/></div>
        <br/>
        <Bold><FormattedMessage id={'purpose_4'}/></Bold>
 <div><FormattedMessage id={'purpose_5'}/></div>
        <br/>
        <Bold><FormattedMessage id={'purpose_6'}/></Bold>
 <div><FormattedMessage id={'purpose_7'}/></div>
    </div>
  </Wrapper>

    )
  }
}


export default PurposeInfo


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
