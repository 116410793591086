
import React from "react";
import styled from "styled-components";
//import { Types } from "gatsby-theme-advanced";
import {constants} from "../../theme";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import {AccessibilityIcon, DigitizationIcon, RenewImageIcon} from "./Icons";




const Wrapper = styled.div`
display:flex;
justify-content:space-between;
padding-top:7rem;
text-align:center;

  @media (max-width: ${constants.breakpoints.sm}) {
  display:block;
  padding-top:0rem;
}
`;

const BenefitContainer = styled.div`
width:33%;



  @media (max-width: ${constants.breakpoints.sm}) {
  width:100%;
  padding-top:5rem;
} 
`

const BenefitIcon = styled.div`

`



const BenefitCaption = styled.div`
font-family:"Madera";
font-size:1rem;
text-transform: uppercase;
padding-top:0.7rem;
`
const BenefitDescription = styled.div`
font-family:"Madera Light";
font-size:0.8rem;
padding-top:1.1rem;

`

class Benefits extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
    }
  }




  componentDidMount() {

  }

  render() {
    return(
<Wrapper>
    <BenefitContainer>
        <AccessibilityIcon/>
        <BenefitCaption><FormattedMessage id={'benefit_1'}/></BenefitCaption>
        <BenefitDescription><FormattedMessage id={'benefit_1_1'}/></BenefitDescription>
    </BenefitContainer>
    <BenefitContainer>
<DigitizationIcon/>
<BenefitCaption><FormattedMessage id={'benefit_2'}/></BenefitCaption>
        <BenefitDescription><FormattedMessage id={'benefit_2_1'}/></BenefitDescription>

    </BenefitContainer>
    <BenefitContainer>
        <RenewImageIcon/>
        <BenefitCaption><FormattedMessage id={'benefit_3'}/></BenefitCaption>
        <BenefitDescription><FormattedMessage id={'benefit_3_1'}/></BenefitDescription>
    </BenefitContainer>

  </Wrapper>

    )
  }
}


export default Benefits


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
