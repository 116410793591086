import React from "react";
import styled from "styled-components";
//import {FormattedMessage } from "gatsby-plugin-react-intl";
import {Me7averseImg1} from "../../../images/Image";
import { Swiper, SwiperSlide } from 'swiper/react';
import {constants} from "../../../theme";
import 'react-typist/dist/Typist.css';
import Typist from "react-typist";
import {FormattedMessage} from "gatsby-plugin-react-intl";
import {BsBoxArrowInRight} from "react-icons/bs";


const SliderWrap = styled.div`
padding: 1rem 16px;
max-width: calc(1144px + 2 * 16px);
width: 100%;
padding: 0;
justify-self: center;
//max-width: calc(1144px + 2 * 16px);
margin:auto;
display:flex;
//padding-top:3.3rem;

   @media (max-width: ${constants.breakpoints.lg}) {
padding-top:0px;

 }

.slick-slider{
width:100%;
}
`

const Slide = styled.div`

color:white;
display:flex !important;
justify-content:center;
align-items:center;
padding:0;

.gatsby-image-wrapper .gatsby-image-wrapper-constrained {
width:50%;

}
`

const SlideContent = styled.div`
`

const Me7aType = styled.div`
font-family:'Madera';
padding: 0 0rem;
display:flex;
align-items:center;
color:rgb(68,68,68);
width:100%;
justify-content:center;
font-size:1.4rem;
//letter-spacing:24px;


   @media (max-width: ${constants.breakpoints.lg}) {
font-size:1rem;

 }

`

const Caption = styled.div`
font-family:"Madera Thin";
font-size:2.2rem;
color:black;
text-align:center;
letter-spacing:0px;
padding-bottom:1rem;


  @media (max-width: ${constants.breakpoints.lg}) {
font-size:1.4rem;
  }
`

const ThinLine = styled.div`
//width:22px;
//height:2px;
background:var(--color-primary);
//margin:3rem auto 2rem auto;

`

const Arrow = styled.div`
padding-right:11px;
padding-top:7px;
font-size:1.7rem;
color:rgba(68,68,68);
`


export default class Met7verseSlider extends React.Component {
  render() {
    return (
        <SliderWrap>
          <Swiper
              spaceBetween={50}
              slidesPerView={1}
          >
            <SwiperSlide>
                <SlideContent>
                    <Slide><Me7averseImg1/></Slide>
                    <ThinLine/>
                    <Caption><FormattedMessage id={'brands_4'}/></Caption>
                    <Me7aType>
                        <Arrow><BsBoxArrowInRight/></Arrow>
                        <Typist avgTypingDelay={170}>
                            <span>meta</span>
                            <Typist.Backspace count={2} delay={900} />
                            <span>7averse.org</span>
                        </Typist>
                    </Me7aType>
                </SlideContent>
            </SwiperSlide>
          </Swiper>
        </SliderWrap>
    );
  }
}


/*
*
            <SwiperSlide><Slide><Me7averseImg2/></Slide></SwiperSlide>
            <SwiperSlide><Slide><Me7averseImg3/></Slide></SwiperSlide>
            <SwiperSlide><Slide><Me7averseImg5/></Slide></SwiperSlide>
*
* */
