
import React from "react";
import styled from "styled-components";
import {FormattedMessage } from "gatsby-plugin-react-intl";

import { Swiper, SwiperSlide } from 'swiper/react';
import {constants} from "../../../theme";
import 'react-typist/dist/Typist.css';
import Typist from "react-typist";

import { BsBoxArrowInRight } from "react-icons/bs";



const SliderWrap = styled.div`
   padding: 1rem 16px;
  max-width: calc(1144px + 2 * 16px);
 width: 100%;
 
padding: 0;
justify-self: center;
//max-width: calc(1144px + 2 * 16px);
margin:auto;
display:flex;
//padding-top:3.3rem;

   @media (max-width: ${constants.breakpoints.lg}) {
padding-top:0px;

 }

.slick-slider{
width:100%;
}
`

const Slide = styled.div`

color:white;
display:flex !important;
justify-content:center;
align-items:center;
padding:0;

.gatsby-image-wrapper .gatsby-image-wrapper-constrained {
width:50%;

}
svg{
max-width:77px;
width:100%;
padding: 0 4rem;
}
   
`

const SlideContent = styled.div`
`

const Me7aType = styled.div`
font-family:'Madera';
padding: 0 0rem;
display:flex;
align-items:center;

width:100%;
justify-content:center;
font-size:1rem;
//letter-spacing:24px;

`

const CloudTitle = styled.div`
font-family:"Madera Thin";
//font-weight:500;
font-size:1.7rem;
color:black;
text-align:center;
letter-spacing:24px;
padding-top:1.3rem;
`

const Caption = styled.div`
font-family:"Madera Thin";
font-size:1.4rem;
color:black;
text-align:center;
letter-spacing:0px;
padding-bottom:1rem;
`

const ThinLine = styled.div`
width:22px;
height:2px;
background:var(--color-primary);
margin:3rem auto 2rem auto;

`

const Arrow = styled.div`
padding-right:11px;
padding-top:4px;
font-size:1.7rem;
color:rgba(68,68,68);
`



export default class CloudSlider extends React.Component {
  render() {

    return (
        <SliderWrap>
          <Swiper
              spaceBetween={50}
              slidesPerView={1}
          >
            <SwiperSlide>
            <SlideContent>
                <Slide>
                    <div>
                    <svg viewBox="0 0 176.84 150" xmlns="http://www.w3.org/2000/svg"><path d="m125.72 149.66v-8.27c1.81-.09 3.34-.22 4.87-.25 12.19-.31 17.23-6.84 13.84-20.1-3.11-12.16-7.69-23.86-11.84-35.66-7.35-20.91-14.86-41.75-22.32-62.61-.64-1.78-1.42-3.49-2.57-6.27l-76.28 133.5h-31.42l86-150h26q9.51 26.06 19.1 52.35c8.7 23.89 17.32 47.81 26.1 71.65 4.88 13.24 8.56 16.4 19.65 17.43v8.23z"/><path d="m7.88 55.84h-7.78c0-18-.2-37.42 0-55.49h44.51c-22.4 11.11-31.69 31.25-36.73 55.49z"/></svg>
            <CloudTitle>CLOUD</CloudTitle>
                    </div>
                </Slide>
                <ThinLine/>
                <Caption><FormattedMessage id={'brands_1'}/></Caption>

                <Me7aType>
                    <Arrow><BsBoxArrowInRight/></Arrow>
                    <Typist avgTypingDelay={170}>
                        <span>7arte.cloud</span>
                    </Typist>
                </Me7aType>

            </SlideContent>
            </SwiperSlide>
          </Swiper>
        </SliderWrap>
    );
  }
}
