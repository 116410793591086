
import React from "react";
import styled from "styled-components";
// import {FormattedMessage } from "gatsby-plugin-react-intl";
import {AkImg1, YkImg1} from "../../images/Image";
//import {DevelopmentIcon} from "../Purpose/Icons";
import {FormattedMessage } from "gatsby-plugin-react-intl";
import {constants} from "../../theme";
import {BsLinkedin} from "react-icons/bs";



const Wrap = styled.div`
  @media (max-width: 860px) {
display:block;
} 

font-family:"Madera";
width: 100%;
padding-bottom: 6rem;

margin:auto;
display:flex;
padding-top:4rem;


img{
filter: grayscale(90%);   
}


  @media (max-width: ${constants.breakpoints.sm}) {
  padding-bottom:0rem;
} 
`

const ImgContainer = styled.div`

`

const Slide = styled.div`
width:361px;
margin-right:6.1rem;

  @media (max-width: 860px) {
margin-right:0px;
padding-bottom:7rem; 
} 

.gatsby-image-wrapper .gatsby-image-wrapper-constrained {
width:100%;         
}

  @media (max-width: ${constants.breakpoints.sm}) {
  width:100%;
} 

    
`

const Title = styled.div`
font-weight:bold;
font-size:1.7rem;
padding-top:2rem;
display:flex;
`

const JobTitle = styled.div`
font-family:'Madera';
font-size:1rem;
font-weight:700;
padding-top:0.2rem;
padding-bottom:0.6rem;
color:rgba(68,68,68,.7);
`

const Relative = styled.div`
position:relative;
overflow:hidden;
height:426.033px;
width:342px;

  @media (max-width: ${constants.breakpoints.sm}) {
  width:100%;
  height:auto;
} 

`

const LinkedIn = styled.a`
cursor:pointer;
font-size:1.3rem;
color:rgba(68,68,68,.5) !important;
transition:.1s all ease;
margin-left:0.7rem;
margin-top:0.6rem;

:hover{
color:rgba(68,68,68,.7) !important;
}
`

const Description = styled.div`
font-family:'Madera Light';
`

export default class Founders extends React.Component {
    render() {

        return (
            <Wrap>
                <Slide>
                    <Relative> <ImgContainer> <YkImg1/></ImgContainer></Relative>
                    <Title>Yvonne<LinkedIn target="_blank" rel="noopener noreferrer" href={'https://www.linkedin.com/in/yvonnekevin/'}><BsLinkedin/></LinkedIn></Title>
                    <JobTitle>Managing Director & Founder</JobTitle>
                    <Description><FormattedMessage id={'team_5'}/></Description>
                </Slide>
                <Slide>
                 <Relative> <ImgContainer> <AkImg1/></ImgContainer></Relative>
                    <Title>Alex<LinkedIn target="_blank" rel="noopener noreferrer" href={'https://www.linkedin.com/in/ialexanderkevin/'}><BsLinkedin/></LinkedIn></Title>
                    <JobTitle>Managing Director & Founder</JobTitle>
                    <Description><FormattedMessage id={'team_6'}/> </Description>
                </Slide>
            </Wrap>
        );
    }
}
