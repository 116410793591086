
import React from "react";
import styled from "styled-components";

//import { Types } from "gatsby-theme-advanced";
import {constants, H1, H2, H3} from "../../theme";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import MarketSlider from "./MarketSlider";
import Benefits from "./Benefits";
import Pillars from "./Pillars";
import Support from "./Support";



const Wrapper = styled.div`
 
width: 100%;

padding: 7.7rem 16px 4.5rem 16px;
justify-self: center;
max-width: calc(1144px + 2 * 16px);
margin:auto;

  @media (max-width: ${constants.breakpoints.sm}) {

  padding-top:1rem;
} 

`;

const MarketDescription = styled.div`
    font-family:"Madera Light";
    padding-top:2.7rem;
`

const Benefit = styled.div`
text-align:center !important;
padding-top:7rem;

  @media (max-width: ${constants.breakpoints.sm}) {
} 

`

const SupportSection = styled.div`
text-align:center;
padding-top:5rem;

`
const PillarSection = styled.div`
padding-top:7rem;
text-align:center;

`

class Market extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
    }
  }




  componentDidMount() {

  }

  render() {
    return(
<Wrapper>
    <H3><FormattedMessage id={'market_0'}/></H3>
  <MarketSlider/>
  <MarketDescription>
    <FormattedMessage id={'market_9'}/>
  </MarketDescription>
  <Benefit>
    <H3> <FormattedMessage id={'benefit_0'}/>
    </H3>
<Benefits/>
  </Benefit>

  <SupportSection>
    <H3><FormattedMessage id={'support_0'}/></H3>
    <Support/>
  </SupportSection>
  <PillarSection>
    <H3><FormattedMessage id={'pillar_0'}/></H3>
    <Pillars/>
  </PillarSection>



  </Wrapper>

    )
  }
}


export default Market


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
