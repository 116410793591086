
import React from "react";
import styled from "styled-components";
//import { Types } from "gatsby-theme-advanced";
import {constants} from "../../theme";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import {ConceptionIcon, ConsultingIcon, DevelopmentIcon, MaintenanceIcon, OperatingIcon, ResearchIcon} from "./Icons";



const Wrapper = styled.div`
padding-top:7rem;
padding-bottom:7rem;
display:flex;
justify-content:space-between;
align-items:center;
  @media (max-width: ${constants.breakpoints.sm}) {
display:block;
padding-bottom:0rem;
} 
`;

const PillarContainer = styled.div`

  @media (max-width: ${constants.breakpoints.sm}) {

  padding-bottom:7rem;
} 
`

const PillarIcon = styled.div`
svg{
width:47px;
height:47px;
}
`

const PillarIconContainer = styled.div`
display:flex;
justify-content:space-between;
max-width:120px;
margin:auto;
`

const PillarCaption = styled.div`
font-family:"Madera";
font-size:1rem;
text-transform: uppercase;
padding-top: 0.7rem;    
`

class Pillars extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
    }
  }




  componentDidMount() {

  }

  render() {
    return(
<Wrapper>

    <PillarContainer>
        <PillarIconContainer>
            <DevelopmentIcon/>
            <ResearchIcon/>
        </PillarIconContainer>
        <PillarCaption><FormattedMessage id={'pillar_1'}/></PillarCaption>
    </PillarContainer>
    <PillarContainer>
        <PillarIconContainer>
<ConsultingIcon/>
<ConceptionIcon/>
        </PillarIconContainer>
        <PillarCaption><FormattedMessage id={'pillar_2'}/></PillarCaption>
    </PillarContainer>
    <PillarContainer>
        <PillarIconContainer>
            <OperatingIcon/>
            <MaintenanceIcon/>
        </PillarIconContainer>
        <PillarCaption><FormattedMessage id={'pillar_3'}/></PillarCaption>
    </PillarContainer>

  </Wrapper>

    )
  }
}


export default Pillars


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
