import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../layouts";
//import Roadmap from "../components/Met7verse/Roadmap";
import styled from "styled-components";
import CoworkingSlider from "../components/Brands/Coworking/CoworkingSlider";

const Container = styled.div`
min-height:70vh;
display:flex;
justify-content:center;
align-items:center;
`

const CoworkingPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <Container className="v7-coworking-container">
          <CoworkingSlider/>

        <Helmet title={`Coworking | ${config.website.title}`} />
      </Container>
    </Layout>
  );
};

export default CoworkingPage;
