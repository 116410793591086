import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import {constants, H2, H3, H4} from "../../theme";
import Fade from 'react-reveal/Fade';
import { BsMouse } from "react-icons/bs";

const Wrap = styled.div`
font-family:"Madera";
 width: 100%;
padding: 0;
//max-width: calc(1144px + 2 * 16px);
padding-top:2rem;

ul{
  list-style-type: none;
}
li {
padding-bottom:1.1rem;
}


  @media (max-width: ${constants.breakpoints.sm}) {
  padding-top:0rem;
} 
`

const ListStyle = styled.div`
transition:all .2s ease;
padding-right:0.3rem;
padding-top:0.3rem;
display:flex;
`

const Slide = styled.div`
width:361px;
margin-right:6.1rem;


.gatsby-image-wrapper .gatsby-image-wrapper-constrained {
width:100%;         
}   
`

const ValueTitle = styled(H4)`
font-size:1rem;
font-weight:bold;
padding-top:2rem;
padding-bottom:2rem;
padding-left:1.3rem;

`


const Value = styled.div`
padding-right:0.3rem;
`

const Values = styled.div`
padding-left:1.3rem;
`

const ValuePurpose = styled(Fade)`
`

const ValueCaption = styled.div`
padding-top:2rem;
`

const ValueDescription = styled.div`
`

const ValueContainer = styled.div`
display:flex;
cursor:pointer;
align-items:center;


`


const CompanyTitle = styled(H2)`
padding-top:0.2rem;
padding-bottom:2.2rem;
`

const CompanyCaption = styled.div`
`





export default class Company extends React.Component {

    constructor(props: any) {
        super(props)
        this.state = {
            value_1:true,
            value_2:false,
            value_3:false,
            value_4:false,
            value_5:false,
            value_6:false,
            value_7:false
        }
        this.toggleValue = this.toggleValue.bind(this)
    }

    toggleValue(id:number, hover:boolean) {
        if (hover === true) {
            if (id === 1) this.setState({value_1:true})
            if (id === 2) this.setState({value_2:true})
            if (id === 3) this.setState({value_3:true})
            if (id === 4) this.setState({value_4:true})
            if (id === 5) this.setState({value_5:true})
            if (id === 6) this.setState({value_6:true})
            if (id === 7) this.setState({value_7:true})
        }
        if (hover === false) {
            if (id === 1) this.setState({value_1:false})
            if (id === 2) this.setState({value_2:false})
            if (id === 3) this.setState({value_3:false})
            if (id === 4) this.setState({value_4:false})
            if (id === 5) this.setState({value_5:false})
            if (id === 6) this.setState({value_6:false})
            if (id === 7) this.setState({value_7:false})
        }
    }





    componentDidMount() {
    }

    render() {



        return (

            <Wrap>
    <CompanyTitle><FormattedMessage id={'team_7'}></FormattedMessage></CompanyTitle>
           <CompanyCaption><FormattedMessage id={'team_8'}></FormattedMessage></CompanyCaption>
                {
                    this.props.locale.locale=== 'de' &&
         <div>       <ValueTitle><FormattedMessage id={'value_0'}></FormattedMessage></ValueTitle>
           <Values>
               <ul>
                   <li><ValueContainer onMouseEnter={()=>this.toggleValue(1, true)} onMouseLeave={()=>this.toggleValue(1, false)}><ListStyle style={{color:this.state.value_1?'black':'lightgrey'}}><BsMouse/></ListStyle><Value><FormattedMessage id={'value_1'}/></Value> <ValuePurpose right spy={this.state.value_1}  when={this.state.value_1}><FormattedMessage id={'value_1_1'}/></ValuePurpose></ValueContainer></li>
                   <li><ValueContainer onMouseEnter={()=>this.toggleValue(2, true)} onMouseLeave={()=>this.toggleValue(2, false)}><ListStyle style={{color:this.state.value_2?'black':'lightgrey'}}><BsMouse/></ListStyle><Value><FormattedMessage id={'value_2'}/></Value><ValuePurpose right spy={this.state.value_2} when={this.state.value_2}><FormattedMessage id={'value_2_1'}/></ValuePurpose></ValueContainer></li>
                   <li><ValueContainer onMouseEnter={()=>this.toggleValue(3, true)} onMouseLeave={()=>this.toggleValue(3, false)}><ListStyle style={{color:this.state.value_3?'black':'lightgrey'}}><BsMouse/></ListStyle><Value><FormattedMessage id={'value_3'}/></Value> <ValuePurpose  right spy={this.state.value_3}  when={this.state.value_3}><FormattedMessage id={'value_3_1'}/></ValuePurpose></ValueContainer></li>
                   <li><ValueContainer onMouseEnter={()=>this.toggleValue(4, true)} onMouseLeave={()=>this.toggleValue(4, false)}><ListStyle style={{color:this.state.value_4?'black':'lightgrey'}}><BsMouse/></ListStyle><Value><FormattedMessage id={'value_4'}/></Value> <ValuePurpose  right spy={this.state.value_4}  when={this.state.value_4}><FormattedMessage id={'value_4_1'}/></ValuePurpose></ValueContainer></li>
                   <li><ValueContainer onMouseEnter={()=>this.toggleValue(5, true)} onMouseLeave={()=>this.toggleValue(5, false)}><ListStyle style={{color:this.state.value_5?'black':'lightgrey'}}><BsMouse/></ListStyle><Value><FormattedMessage id={'value_5'}/></Value><ValuePurpose  right spy={this.state.value_5}  when={this.state.value_5}><FormattedMessage id={'value_5_1'}/></ValuePurpose></ValueContainer></li>
                   <li><ValueContainer onMouseEnter={()=>this.toggleValue(6, true)} onMouseLeave={()=>this.toggleValue(6, false)}><ListStyle style={{color:this.state.value_6?'black':'lightgrey'}}><BsMouse/></ListStyle><Value><FormattedMessage id={'value_6'}/></Value><ValuePurpose  right spy={this.state.value_6}  when={this.state.value_6}><FormattedMessage id={'value_6_1'}/></ValuePurpose></ValueContainer></li>
                   <li><ValueContainer onMouseEnter={()=>this.toggleValue(7, true)} onMouseLeave={()=>this.toggleValue(7, false)}><ListStyle style={{color:this.state.value_7?'black':'lightgrey'}}><BsMouse/></ListStyle><Value><FormattedMessage id={'value_7'}/></Value><ValuePurpose  right spy={this.state.value_7}  when={this.state.value_7}><FormattedMessage id={'value_7_1'}/></ValuePurpose></ValueContainer></li>
               </ul>
           </Values>

                <ValueCaption><FormattedMessage id={'team_9'} ></FormattedMessage></ValueCaption>
         </div> }



            </Wrap>
        );
    }
}
