import React from "react";
import {FormattedMessage } from "gatsby-plugin-react-intl";
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, FreeMode, Pagination} from 'swiper';
import { VscChevronLeft } from "react-icons/vsc";
import { VscChevronRight } from "react-icons/vsc";
import 'swiper/css';
import 'swiper/css/effect-coverflow'


import styled from "styled-components";

const YearCaption = styled.div`
padding-top:1.2rem;
font-size:0.7rem;
font-family:"Madera";
text-align:center;
opacity:.4;
`

const Navigation = styled.div`
display:flex;
justify-content:center;

`

const Button = styled.div`
display:flex;
justify-content:center;
align-items:center;
background:lightgrey;
border-radius:50%;
padding:16px;
font-size:2.1rem;
`


const VerticalSlider = styled.div`
padding-top:1rem;
width:100%;
margin:auto;
`

const SliderWrap = styled.div`
padding-top:1rem;

.swiper-slide {
transition:all 3s ease;
background:rgb(32,32,32);
color:rgb(191,191,191);
}



/*
.swiper-slide-prev {
transition:all 3s ease;
background: linear-gradient(to left, white, transparent 90%);
}
*/



`




const Slide = styled.div`
width:100%;
height:94px;

display:flex !important;
justify-content:center;
align-items:center;
text-align:center;
`

const SlideContent = styled.div`
`

const SlideTitle = styled.div`
font-size:0.7rem;
font-family:"Madera";
`

const SlideCaption = styled.div`
font-size:1.5rem;
font-family:"Madera Light";
`
const SlideCaptionYear = styled.div`
font-size:0.6rem;
font-family:"Madera Light";
`

export default class MarketSlider extends React.Component {
  render() {
    return (
        <SliderWrap>
          <VerticalSlider>
            <Swiper
                modules={[FreeMode, Autoplay, Pagination]} loop={true}
                autoplay={{delay:0, reverseDirection:true}}
                slidesPerView={3}
                speed={3000}
                pagination={{ clickable: true }}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  840: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  1040: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  }
                }}
            >
              <SwiperSlide>
              <Slide>
              <SlideContent>
              <SlideTitle><FormattedMessage id={'market_1'}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_1_1'}/></SlideCaption>
              </SlideContent>
              </Slide>
              </SwiperSlide>
              <SwiperSlide> <Slide><SlideContent>
              <SlideTitle><FormattedMessage id={'market_2'}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_2_1'}/></SlideCaption> </SlideContent></Slide></SwiperSlide>
              <SwiperSlide>   <Slide><SlideContent>
              <SlideTitle><FormattedMessage id={'market_3'}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_3_1'}/></SlideCaption> </SlideContent></Slide></SwiperSlide>
              <SwiperSlide>  <Slide><SlideContent>
              <SlideTitle><FormattedMessage id={'market_4'}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_4_1'}/></SlideCaption> </SlideContent></Slide></SwiperSlide>
            </Swiper>
          </VerticalSlider>
          <VerticalSlider>
            <Swiper
                modules={[FreeMode, Autoplay, Pagination]}
                loop={true}
                autoplay={{delay:0, reverseDirection:false}}
                speed={3000}
                slidesPerView={3}
                pagination={{ clickable: true }}
                spaceBetween={30}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  840: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  1040: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  }
                }}
            >
              <SwiperSlide> <Slide><SlideContent>
              <SlideTitle>  <FormattedMessage id={'market_7'}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_7_1'}/></SlideCaption> </SlideContent></Slide></SwiperSlide>
              <SwiperSlide> <Slide><SlideContent>
              <SlideTitle>  <FormattedMessage id={'market_6'} values={{
                  div: (...chunks: any) => <div>{chunks}</div>,
                  br: <br />
              }}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_6_1'}/></SlideCaption> </SlideContent></Slide></SwiperSlide>
              <SwiperSlide> <Slide><SlideContent>
              <SlideTitle>  <FormattedMessage id={'market_5'} values={{
                  div: (...chunks: any) => <div>{chunks}</div>,
                  br: <br />
              }}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_5_1'}/></SlideCaption> </SlideContent></Slide></SwiperSlide>
              <SwiperSlide> <Slide><SlideContent>
              <SlideTitle>  <FormattedMessage id={'market_8'} values={{
                  div: (...chunks: any) => <div>{chunks}</div>,
                  br: <br />
              }}/></SlideTitle>
              <SlideCaption><FormattedMessage id={'market_8_1'}/></SlideCaption> </SlideContent></Slide></SwiperSlide>
            </Swiper>
          </VerticalSlider>
            <YearCaption><FormattedMessage id={'market_10'}/></YearCaption>
      </SliderWrap>
    );
  }
}
