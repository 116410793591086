import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../layouts";
//import Roadmap from "../components/Met7verse/Roadmap";
import styled from "styled-components";
import CloudSlider from "../components/Brands/Cloud/CloudSlider";

const Container = styled.div`
min-height:70vh;
display:flex;
justify-content:center;
align-items:center;
`

const CloudPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <Container className="7a-cloud-container">
          <CloudSlider/>
        <Helmet title={`Cloud | ${config.website.title}`} />
      </Container>
    </Layout>
  );
};

export default CloudPage;
