import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../layouts";
import {H1, H4} from "../theme";

import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";


import styled from "styled-components";
//import TeamSlider from "../components/Team/TeamSlider";
import Founders from "../components/Team/Founders";
import Company from "../components/Team/Company";
import TeamSlider from "../components/Team/TeamSlider";



const Wrapper = styled.div`
font-family:"Madera";
width: 100%;
padding: 9rem 16px 4.5rem 16px;
justify-self: center;

`

const H = styled.div`
max-width: calc(1144px + 2 * 16px);
margin:auto;
`

const OurTeamPage = (): JSX.Element => {
  const config = useConfig();
const locale = useIntl()
  return (
    <Layout>
      <div className="our-team-container">
         <Wrapper>
             <H>
            <H1><FormattedMessage id={'team_1'}/></H1><br/><br/><br/>
             <H4><FormattedMessage id={'team_2'}/></H4><br/>
             </H>

             <H>
             <div><FormattedMessage id={'team_3'}/></div><br/><br/><br/>
             <H1><FormattedMessage id={'team_4'}/></H1>
             <Founders/>
             <Company locale={locale}/>
             </H>
         </Wrapper>
        <Helmet title={`Our Team | ${config.website.title}`} />
      </div>
    </Layout>
  );
};

export default OurTeamPage;
