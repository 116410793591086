import * as React from "react";
import { Helmet } from "react-helmet";

import { useConfig } from "gatsby-theme-advanced";

import Layout from "../layouts";
import Met7verseSlider from "../components/Brands/Met7verse/Met7verseSlider";
//import Roadmap from "../components/Met7verse/Roadmap";
import styled from "styled-components";

const Me7averseContainer = styled.div`
min-height:70vh;
display:flex;
justify-content:center;
align-items:center;
`

const Me7aversePage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <Me7averseContainer className="contact-container">
          <Met7verseSlider/>
        <Helmet title={`Me7averse | ${config.website.title}`} />
      </Me7averseContainer>
    </Layout>
  );
};

export default Me7aversePage;
