
import React from "react";
import styled from "styled-components";
//import {FormattedMessage } from "gatsby-plugin-react-intl";
import {Me7averseImg1} from "../../../images/Image";

import { Swiper, SwiperSlide } from 'swiper/react';
import {constants} from "../../../theme";
import 'react-typist/dist/Typist.css';
import Typist from "react-typist";
import {BsBoxArrowInRight} from "react-icons/bs";
import {FormattedMessage} from "gatsby-plugin-react-intl";

const V7CoworkingIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.02 189.52">
    <defs>
        <style>

        </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <g id="Ebene_1" data-name="Ebene 1">
            <g>
                <g>
                    <path className="cls-1"
                          d="M67.2,165.17l.1-.35h5.55a10.28,10.28,0,0,1,2.3.24,5.42,5.42,0,0,1,1.8.71,3.56,3.56,0,0,1,1.18,1.2,3.08,3.08,0,0,1,.44,1.65,3.15,3.15,0,0,1-.35,1.49,4.08,4.08,0,0,1-.89,1.13,5,5,0,0,1-1.22.8,8.49,8.49,0,0,1-1.33.52,7.68,7.68,0,0,1-1.23.28,6.35,6.35,0,0,1-.92.09l0,0c.21.05.55.16,1,.32A6,6,0,0,1,75,174a4.62,4.62,0,0,1,1.23,1.26,3.3,3.3,0,0,1,.53,1.9,3.69,3.69,0,0,1-.73,2.27,5.84,5.84,0,0,1-1.89,1.65,9.34,9.34,0,0,1-2.64,1,13.27,13.27,0,0,1-2.92.33c-.61,0-1.2,0-1.79,0s-1.18,0-1.81,0H61.39l.1-.34H64l6.08-16.86Zm.13,16.92q.54,0,1.08,0a6,6,0,0,0,2.75-.58,6,6,0,0,0,1.9-1.44,5.66,5.66,0,0,0,1.11-1.91,6.25,6.25,0,0,0,.35-2,3.88,3.88,0,0,0-.14-1.08,2.84,2.84,0,0,0-.39-.8,3.09,3.09,0,0,0-.5-.57,2.42,2.42,0,0,0-.54-.36,3.18,3.18,0,0,0-1-.3,10.84,10.84,0,0,0-1.27-.07H69.42L66.24,182Zm4.53-9.38a4.75,4.75,0,0,0,1.76-.32,3.9,3.9,0,0,0,1.4-.93,4.12,4.12,0,0,0,.91-1.49,5.89,5.89,0,0,0,.32-2,2.79,2.79,0,0,0-.77-2,2.91,2.91,0,0,0-2.16-.77h-1l-2.71,7.54Z"/>
                    <path className="cls-1"
                          d="M80.14,175.73a7.66,7.66,0,0,1,1.57-2.23,7.36,7.36,0,0,1,2.1-1.48,5.31,5.31,0,0,1,2.22-.54,3.47,3.47,0,0,1,1.68.39,3.73,3.73,0,0,1,1.18,1,4.26,4.26,0,0,1,.69,1.36,4.88,4.88,0,0,1,.22,1.43,6.13,6.13,0,0,1-.61,2.7,8.43,8.43,0,0,1-1.56,2.23,7.24,7.24,0,0,1-2.08,1.51,5,5,0,0,1-2.18.56,3.57,3.57,0,0,1-1.59-.35,3.9,3.9,0,0,1-1.21-.91,4.13,4.13,0,0,1-.77-1.32,4.66,4.66,0,0,1-.27-1.59A6.34,6.34,0,0,1,80.14,175.73Zm1.62,6a2.09,2.09,0,0,0,1.54.61,2.52,2.52,0,0,0,1.43-.45,4.73,4.73,0,0,0,1.2-1.17,8.82,8.82,0,0,0,1-1.61,16.39,16.39,0,0,0,.7-1.78,14.21,14.21,0,0,0,.43-1.67,7.28,7.28,0,0,0,.15-1.29,3.09,3.09,0,0,0-.51-1.87,2,2,0,0,0-1.76-.69,2.44,2.44,0,0,0-1.36.42,5.24,5.24,0,0,0-1.17,1.1,8.55,8.55,0,0,0-.95,1.54,12.64,12.64,0,0,0-.7,1.76,13.5,13.5,0,0,0-.43,1.75,8.71,8.71,0,0,0-.15,1.49A2.5,2.5,0,0,0,81.76,181.74Z"/>
                    <path className="cls-1"
                          d="M96.48,171.8l-2.83,8.13a9.63,9.63,0,0,0-.3.92,3.52,3.52,0,0,0-.1.61,1,1,0,0,0,.19.61.71.71,0,0,0,.63.28,2.68,2.68,0,0,0,1.44-.51A8.43,8.43,0,0,0,97,180.55a11.52,11.52,0,0,0,1.36-1.77,9.63,9.63,0,0,0,1-1.93l1.75-5h1.73l-2.91,8.35c-.08.25-.17.5-.25.75a2.47,2.47,0,0,0-.12.73.8.8,0,0,0,.17.51.64.64,0,0,0,.52.21,1.39,1.39,0,0,0,.8-.28,4.5,4.5,0,0,0,.69-.62,5.86,5.86,0,0,0,.53-.73,6.5,6.5,0,0,0,.32-.59l.29.12q-.09.19-.3.6a3.86,3.86,0,0,1-.58.82,3.61,3.61,0,0,1-.91.7,2.64,2.64,0,0,1-1.29.3,2.24,2.24,0,0,1-1.29-.34,1.26,1.26,0,0,1-.48-1.12,3,3,0,0,1,.1-.8,6.78,6.78,0,0,1,.29-.87l-.05,0c-.23.29-.5.63-.82,1a10.21,10.21,0,0,1-1,1,6.19,6.19,0,0,1-1.18.81,2.76,2.76,0,0,1-1.29.34,2.61,2.61,0,0,1-1.68-.5,1.72,1.72,0,0,1-.61-1.43,2.65,2.65,0,0,1,.17-.87c.11-.34.21-.64.29-.9l2.42-6.83H92.42l.09-.34Z"/>
                    <path className="cls-1"
                          d="M110.21,169.38l-.89,2.42H112l-.1.34h-2.69L106.41,180l-.24.76a2.47,2.47,0,0,0-.13.79.89.89,0,0,0,.19.59.73.73,0,0,0,.6.23,1.46,1.46,0,0,0,.84-.28,4.71,4.71,0,0,0,.72-.62,5.08,5.08,0,0,0,.54-.73,6.5,6.5,0,0,0,.32-.59l.29.12c-.06.13-.17.33-.32.6a3.59,3.59,0,0,1-.59.82,3.33,3.33,0,0,1-.92.7,2.74,2.74,0,0,1-1.32.3,2,2,0,0,1-1.41-.46,1.47,1.47,0,0,1-.46-1.1,4.17,4.17,0,0,1,.18-1.12c.12-.42.25-.8.38-1.14l2.42-6.71h-2.22l.1-.34h2.22l.88-2.42Z"/>
                    <path className="cls-1"
                          d="M112.6,172.14l.1-.34h3.92l-2.91,8.35c-.08.25-.17.5-.25.75a2.47,2.47,0,0,0-.12.73.8.8,0,0,0,.17.51.64.64,0,0,0,.52.21,1.33,1.33,0,0,0,.77-.28,4.64,4.64,0,0,0,.71-.62,5.08,5.08,0,0,0,.54-.73,6.5,6.5,0,0,0,.32-.59l.3.12-.31.6a3.86,3.86,0,0,1-.58.82,3.61,3.61,0,0,1-.91.7,2.64,2.64,0,0,1-1.29.3,2.24,2.24,0,0,1-1.29-.34,1.26,1.26,0,0,1-.48-1.12,3.2,3.2,0,0,1,.15-.92c.1-.34.2-.65.29-.93l2.54-7.22Zm4.05-7a1.12,1.12,0,0,1,.83-.34,1.16,1.16,0,0,1,1.16,1.16,1.14,1.14,0,0,1-1.16,1.16,1.13,1.13,0,0,1-1.16-1.16A1.11,1.11,0,0,1,116.65,165.16Z"/>
                    <path className="cls-1"
                          d="M125.39,180c-.16.22-.38.48-.65.78a7.38,7.38,0,0,1-.95.88,6.43,6.43,0,0,1-1.2.71,3.28,3.28,0,0,1-1.39.3,3.16,3.16,0,0,1-1-.18,2.64,2.64,0,0,1-1.58-1.43,2.77,2.77,0,0,1-.26-1.25,5.52,5.52,0,0,1,.38-2,9,9,0,0,1,1-1.94,11.2,11.2,0,0,1,1.45-1.72,13.33,13.33,0,0,1,1.69-1.4,8.91,8.91,0,0,1,1.7-.92,4.23,4.23,0,0,1,1.53-.33,1.89,1.89,0,0,1,.83.17,1.62,1.62,0,0,1,.53.43,1.76,1.76,0,0,1,.29.56,1.94,1.94,0,0,1,.12.56h.05l.47-1.4h1.73L124,188.9h2l-.1.35H120l.1-.35h2.16l3.21-8.85Zm2.08-6a2.88,2.88,0,0,0,0-.47,2,2,0,0,0-.38-1.28,1.3,1.3,0,0,0-1-.47,2.7,2.7,0,0,0-1.48.48,7.17,7.17,0,0,0-1.42,1.27,12.83,12.83,0,0,0-1.24,1.76,18.37,18.37,0,0,0-1,2,11.89,11.89,0,0,0-.66,1.89,6.11,6.11,0,0,0-.24,1.52,2,2,0,0,0,.32,1.13,1.16,1.16,0,0,0,1,.5,2.72,2.72,0,0,0,1.43-.47,8.43,8.43,0,0,0,1.38-1.1,11.66,11.66,0,0,0,1.11-1.28,5.27,5.27,0,0,0,.61-1.05l1.38-3.84A2.24,2.24,0,0,0,127.47,174Z"/>
                    <path className="cls-1"
                          d="M136.65,171.8l-2.83,8.13a9.63,9.63,0,0,0-.3.92,3.52,3.52,0,0,0-.1.61,1,1,0,0,0,.19.61.71.71,0,0,0,.63.28,2.68,2.68,0,0,0,1.44-.51,8.43,8.43,0,0,0,1.51-1.29,11.52,11.52,0,0,0,1.36-1.77,9.63,9.63,0,0,0,1-1.93l1.75-5H143l-2.91,8.35c-.08.25-.17.5-.25.75a2.47,2.47,0,0,0-.12.73.8.8,0,0,0,.17.51.64.64,0,0,0,.52.21,1.39,1.39,0,0,0,.8-.28,4.5,4.5,0,0,0,.69-.62,5.86,5.86,0,0,0,.53-.73,6.5,6.5,0,0,0,.32-.59l.29.12q-.09.19-.3.6a3.86,3.86,0,0,1-.58.82,3.61,3.61,0,0,1-.91.7,2.64,2.64,0,0,1-1.29.3,2.24,2.24,0,0,1-1.29-.34,1.26,1.26,0,0,1-.48-1.12,3,3,0,0,1,.1-.8,6.78,6.78,0,0,1,.29-.87l0,0c-.23.29-.5.63-.82,1a10.21,10.21,0,0,1-1,1,6.19,6.19,0,0,1-1.18.81,2.76,2.76,0,0,1-1.29.34,2.61,2.61,0,0,1-1.68-.5,1.72,1.72,0,0,1-.61-1.43,2.65,2.65,0,0,1,.17-.87c.11-.34.21-.64.29-.9l2.42-6.83h-2.19l.09-.34Z"/>
                    <path className="cls-1"
                          d="M152.13,180.58a4.77,4.77,0,0,1-.81.92,5.77,5.77,0,0,1-1.27.81,4.22,4.22,0,0,1-1.82.36,4.12,4.12,0,0,1-1.78-.35,3.37,3.37,0,0,1-1.18-.89,3.44,3.44,0,0,1-.67-1.19,4.17,4.17,0,0,1-.21-1.27,6.15,6.15,0,0,1,.6-2.55,9.89,9.89,0,0,1,1.58-2.42,9.09,9.09,0,0,1,2.21-1.81,5,5,0,0,1,2.51-.71,4.17,4.17,0,0,1,1,.11,3.08,3.08,0,0,1,.88.33,1.87,1.87,0,0,1,.64.57,1.37,1.37,0,0,1,.25.84,2,2,0,0,1-.36,1.17,3.17,3.17,0,0,1-.92.86,6,6,0,0,1-1.25.6,9.06,9.06,0,0,1-1.31.38c-.43.09-.82.16-1.17.2s-.62.06-.78.06l-.81,0a5.5,5.5,0,0,1-.69-.08,16.3,16.3,0,0,0-.46,1.57,7.23,7.23,0,0,0-.18,1.64,2.59,2.59,0,0,0,.69,2,2.42,2.42,0,0,0,1.75.66A3.12,3.12,0,0,0,150,182a5.1,5.1,0,0,0,1.06-.73,5.34,5.34,0,0,0,.76-.85c.21-.29.37-.51.51-.68l.29.18A6.49,6.49,0,0,0,152.13,180.58Zm.1-8.41a1.25,1.25,0,0,0-1-.35,2.69,2.69,0,0,0-1.47.45,5.65,5.65,0,0,0-1.26,1.09,8.53,8.53,0,0,0-1,1.41,8.78,8.78,0,0,0-.64,1.39,7,7,0,0,0,1.26.1,5.08,5.08,0,0,0,1.67-.27,4.94,4.94,0,0,0,1.39-.72,3.5,3.5,0,0,0,1-1,2.45,2.45,0,0,0,.35-1.24A1.27,1.27,0,0,0,152.23,172.17Z"/>
                    <path className="cls-1"
                          d="M176,169.58c0-.17.06-.4.1-.71s0-.64,0-1a2.9,2.9,0,0,0-.3-1.36,2.56,2.56,0,0,0-.8-.95,3.69,3.69,0,0,0-1.2-.56,5.8,5.8,0,0,0-1.47-.18,4.14,4.14,0,0,0-2.39.74,8.75,8.75,0,0,0-2,1.92,15.88,15.88,0,0,0-1.65,2.67,27.07,27.07,0,0,0-1.23,3,22.13,22.13,0,0,0-.77,2.88,13.28,13.28,0,0,0-.25,2.37,3.8,3.8,0,0,0,1.08,3,4.45,4.45,0,0,0,3.08,1,4.71,4.71,0,0,0,2-.39,6.54,6.54,0,0,0,1.53-1,5.89,5.89,0,0,0,1.12-1.23,11.2,11.2,0,0,0,.75-1.2l.32.17c-.09.19-.28.51-.54.95a6.57,6.57,0,0,1-1.09,1.31,7,7,0,0,1-1.72,1.18,5.37,5.37,0,0,1-2.39.5,6,6,0,0,1-2.82-.6,5.39,5.39,0,0,1-1.85-1.58,6.39,6.39,0,0,1-1-2.23,11.13,11.13,0,0,1-.31-2.59,9.23,9.23,0,0,1,.46-2.91,11.82,11.82,0,0,1,1.23-2.65,12.87,12.87,0,0,1,1.81-2.28,13.22,13.22,0,0,1,2.18-1.77,11.5,11.5,0,0,1,2.33-1.16,6.8,6.8,0,0,1,2.27-.42,6.1,6.1,0,0,1,1.43.15,9.75,9.75,0,0,1,1.1.33l.87.34a2.36,2.36,0,0,0,.76.14,1,1,0,0,0,.65-.21,1.5,1.5,0,0,0,.39-.5l.37.07-1.7,4.76Z"/>
                    <path className="cls-1"
                          d="M177.34,175.73a7.82,7.82,0,0,1,1.56-2.23A7.54,7.54,0,0,1,181,172a5.24,5.24,0,0,1,2.22-.54,3.47,3.47,0,0,1,1.68.39,3.73,3.73,0,0,1,1.18,1,4.26,4.26,0,0,1,.69,1.36,4.88,4.88,0,0,1,.22,1.43,6.14,6.14,0,0,1-.62,2.7,8.39,8.39,0,0,1-1.55,2.23,7.38,7.38,0,0,1-2.08,1.51,5,5,0,0,1-2.18.56,3.57,3.57,0,0,1-1.59-.35,3.9,3.9,0,0,1-1.21-.91,4,4,0,0,1-.78-1.32,4.66,4.66,0,0,1-.27-1.59A6.35,6.35,0,0,1,177.34,175.73Zm1.61,6a2.12,2.12,0,0,0,1.54.61,2.5,2.5,0,0,0,1.43-.45,4.61,4.61,0,0,0,1.21-1.17,9.39,9.39,0,0,0,1-1.61,13.12,13.12,0,0,0,.7-1.78,14.36,14.36,0,0,0,.44-1.67,8.16,8.16,0,0,0,.14-1.29,3.09,3.09,0,0,0-.5-1.87,2.05,2.05,0,0,0-1.76-.69,2.44,2.44,0,0,0-1.36.42,4.84,4.84,0,0,0-1.17,1.1,8.55,8.55,0,0,0-1,1.54,12.64,12.64,0,0,0-.7,1.76,13.5,13.5,0,0,0-.43,1.75,8.71,8.71,0,0,0-.15,1.49A2.54,2.54,0,0,0,179,181.74Z"/>
                    <path className="cls-1"
                          d="M199.67,172.06a5.94,5.94,0,0,0-.68.78,5.85,5.85,0,0,0-.59,1,3.19,3.19,0,0,0-.26,1.26,5.54,5.54,0,0,0,.25,1.72c.16.53.36,1.08.59,1.66.14.39.26.75.35,1.07a4.56,4.56,0,0,1,.17.9l1.3-1.06a16.56,16.56,0,0,0,1.45-1.33,8.19,8.19,0,0,0,1.18-1.54,3.3,3.3,0,0,0,.48-1.67,2.68,2.68,0,0,0-.13-.92,3.06,3.06,0,0,0-.28-.63,5.36,5.36,0,0,1-.28-.52,1.13,1.13,0,0,1-.13-.56.64.64,0,0,1,.34-.61,1.63,1.63,0,0,1,.8-.18q1.2,0,1.2,1.8a4.38,4.38,0,0,1-.29,1.59,7,7,0,0,1-.77,1.46,9.37,9.37,0,0,1-1.07,1.32c-.4.42-.8.8-1.19,1.15l-1.26,1.08a9.1,9.1,0,0,1-1,.79,5.49,5.49,0,0,0-.76.64c-.26.26-.49.51-.68.74a4.15,4.15,0,0,0-.43.62l-.32-.18a1.71,1.71,0,0,0,.3-1.11,2.55,2.55,0,0,0-.1-.72l-.22-.66-.52-1.42c-.1-.33-.18-.6-.25-.79s-.12-.39-.17-.57c-.23.23-.48.46-.74.7s-.55.52-.86.83l-1.26,1.08a10.25,10.25,0,0,1-1,.79,5,5,0,0,0-.77.64,6.27,6.27,0,0,0-1.11,1.41l-.32-.18a1.81,1.81,0,0,0,.3-1.16,2.55,2.55,0,0,0-.1-.72l-.22-.65-.52-1.43c-.15-.38-.28-.77-.41-1.16a3.66,3.66,0,0,1,.62-3.42,12.61,12.61,0,0,1,2.08-2.25l.52-.4.22.25-.48.48a8.1,8.1,0,0,0-.67.79,6,6,0,0,0-.59,1,3,3,0,0,0-.25,1.24,6,6,0,0,0,.23,1.73c.15.52.35,1.07.58,1.65.15.39.27.75.36,1.07a4.5,4.5,0,0,1,.16.9l1.3-1.06a16.68,16.68,0,0,0,1.46-1.33,8.13,8.13,0,0,0,1.17-1.54,3.3,3.3,0,0,0,.48-1.67,2.68,2.68,0,0,0-.13-.92,3.06,3.06,0,0,0-.28-.63,5.36,5.36,0,0,1-.28-.52,1.13,1.13,0,0,1-.13-.56.59.59,0,0,1,.34-.57,1.78,1.78,0,0,1,.8-.17,1.1,1.1,0,0,1,.82.45,1.34,1.34,0,0,1,.31.83,10.8,10.8,0,0,1,.83-.83q.48-.42.75-.63l.22.25C200.06,171.67,199.9,171.83,199.67,172.06Z"/>
                    <path className="cls-1"
                          d="M207.46,175.73A7.82,7.82,0,0,1,209,173.5a7.26,7.26,0,0,1,2.11-1.48,5.23,5.23,0,0,1,2.21-.54,3.45,3.45,0,0,1,1.68.39,3.62,3.62,0,0,1,1.18,1,3.89,3.89,0,0,1,.69,1.36,4.9,4.9,0,0,1,.23,1.43,6.14,6.14,0,0,1-.62,2.7A8.63,8.63,0,0,1,215,180.6a7.42,7.42,0,0,1-2.09,1.51,5,5,0,0,1-2.18.56,3.57,3.57,0,0,1-1.59-.35,3.85,3.85,0,0,1-1.2-.91,4,4,0,0,1-.78-1.32,4.66,4.66,0,0,1-.27-1.59A6.21,6.21,0,0,1,207.46,175.73Zm1.61,6a2.1,2.1,0,0,0,1.54.61,2.5,2.5,0,0,0,1.43-.45,4.94,4.94,0,0,0,1.21-1.17,9.39,9.39,0,0,0,1-1.61,14.57,14.57,0,0,0,.7-1.78,14.21,14.21,0,0,0,.43-1.67,7.28,7.28,0,0,0,.15-1.29,3.09,3.09,0,0,0-.51-1.87,2,2,0,0,0-1.76-.69,2.41,2.41,0,0,0-1.35.42,4.84,4.84,0,0,0-1.17,1.1,8,8,0,0,0-.95,1.54,12.7,12.7,0,0,0-.71,1.76,15.35,15.35,0,0,0-.43,1.75,8.6,8.6,0,0,0-.14,1.49A2.54,2.54,0,0,0,209.07,181.74Z"/>
                    <path className="cls-1"
                          d="M221.9,173.47l.39-1.33h-2.14l.1-.34H224c-.08.34-.2.78-.36,1.3s-.32,1-.5,1.53l0,.05c.16-.25.37-.54.61-.89a9.58,9.58,0,0,1,.84-1,4.82,4.82,0,0,1,1-.82,2.33,2.33,0,0,1,1.2-.33,1.8,1.8,0,0,1,1.4.52,1.48,1.48,0,0,1,.45,1.11,1.39,1.39,0,0,1-.13.65,1,1,0,0,1-.35.39,1.26,1.26,0,0,1-.43.19,2.18,2.18,0,0,1-.42.05.84.84,0,0,1-.56-.18.59.59,0,0,1-.23-.51.67.67,0,0,1,.11-.41l.24-.26a2,2,0,0,0,.23-.27.6.6,0,0,0,.11-.39.47.47,0,0,0-.19-.4.82.82,0,0,0-.5-.14,1.86,1.86,0,0,0-1.08.48,7.48,7.48,0,0,0-1.22,1.23,12.87,12.87,0,0,0-2,3.63l-1.78,5h-1.72l2.66-7.59C221.63,174.35,221.77,173.92,221.9,173.47Z"/>
                    <path className="cls-1"
                          d="M233,164l.1-.35h3.94l-4.24,11.88h.52a2.31,2.31,0,0,0,1.6-.67,9,9,0,0,0,1.36-1.52c.92-1.24,1.81-1.85,2.69-1.85a1.31,1.31,0,0,1,.9.31,1.33,1.33,0,0,1,.35,1,1.26,1.26,0,0,1-.29.88,1,1,0,0,1-.74.31c-.59,0-.89-.23-.89-.67a.78.78,0,0,1,.1-.46.66.66,0,0,0,.1-.38c0-.19-.13-.29-.4-.29a1,1,0,0,0-.63.33c-.22.22-.48.52-.8.9l-.61.74a6.21,6.21,0,0,1-.93,1,2.27,2.27,0,0,1-.75.48v.08l.58.11a1.41,1.41,0,0,1,.59.26,2,2,0,0,1,.53.56,2.67,2.67,0,0,1,.37,1l.64,3.28a5,5,0,0,0,.27.85.6.6,0,0,0,.57.38,1,1,0,0,0,.65-.27,3.48,3.48,0,0,0,.56-.64,8.09,8.09,0,0,0,.42-.74q.17-.37.24-.57l.35.1c-.12.26-.25.54-.41.85a3.94,3.94,0,0,1-.58.84,3.42,3.42,0,0,1-.81.64,2.28,2.28,0,0,1-1.11.26,1.83,1.83,0,0,1-1.13-.34,2.36,2.36,0,0,1-.69-1.41l-.67-3.08c0-.27-.1-.52-.16-.76a2.3,2.3,0,0,0-.25-.62,1.14,1.14,0,0,0-.43-.44,1.46,1.46,0,0,0-.71-.16h-.54l-2.32,6.51h-1.7L235.21,164Z"/>
                    <path className="cls-1"
                          d="M242.65,172.14l.1-.34h3.91l-2.9,8.35c-.09.25-.17.5-.25.75a2.47,2.47,0,0,0-.12.73.75.75,0,0,0,.17.51.63.63,0,0,0,.52.21,1.33,1.33,0,0,0,.77-.28,4.64,4.64,0,0,0,.71-.62,5.08,5.08,0,0,0,.54-.73,6.5,6.5,0,0,0,.32-.59l.29.12-.3.6a3.86,3.86,0,0,1-.58.82,3.61,3.61,0,0,1-.91.7,2.64,2.64,0,0,1-1.29.3,2.24,2.24,0,0,1-1.29-.34,1.26,1.26,0,0,1-.48-1.12,3.2,3.2,0,0,1,.15-.92c.1-.34.19-.65.29-.93l2.54-7.22Zm4-7a1.1,1.1,0,0,1,.83-.34,1.08,1.08,0,0,1,.82.34,1.12,1.12,0,0,1,.34.82,1.16,1.16,0,0,1-.34.83,1.11,1.11,0,0,1-.82.33,1.13,1.13,0,0,1-.83-.33,1.15,1.15,0,0,1-.33-.83A1.11,1.11,0,0,1,246.7,165.16Z"/>
                    <path className="cls-1"
                          d="M249.6,172.14l.1-.34h3.89l-1,3h0c1.59-2.12,3.1-3.18,4.51-3.18a2.32,2.32,0,0,1,1.64.55,1.8,1.8,0,0,1,.6,1.37,2.62,2.62,0,0,1-.13.83c-.09.27-.21.59-.34,1l-1.6,4.56c-.1.28-.19.55-.27.83a2.77,2.77,0,0,0-.12.8,1.25,1.25,0,0,0,.12.54.53.53,0,0,0,.52.25,1.49,1.49,0,0,0,.81-.25,3.79,3.79,0,0,0,.68-.59,4.68,4.68,0,0,0,.53-.73,6,6,0,0,0,.34-.65l.27.12c-.08.16-.19.38-.34.65a4.53,4.53,0,0,1-.58.81,3.47,3.47,0,0,1-.87.67,2.55,2.55,0,0,1-1.24.29,2.44,2.44,0,0,1-1.33-.35,1.24,1.24,0,0,1-.54-1.13,2.63,2.63,0,0,1,.09-.67,7.14,7.14,0,0,1,.21-.74l2.07-5.89a3.21,3.21,0,0,0,.19-1,1.05,1.05,0,0,0-.21-.62.85.85,0,0,0-.75-.29,2.58,2.58,0,0,0-1.25.4,6.67,6.67,0,0,0-1.43,1.11,10.27,10.27,0,0,0-1.35,1.65,9.2,9.2,0,0,0-1,2.06L250,182.37h-1.7l3.55-10.23Z"/>
                    <path className="cls-1"
                          d="M269.79,182.12a2.28,2.28,0,0,1,.93,2,4.3,4.3,0,0,1-.42,1.77,5.75,5.75,0,0,1-1.21,1.74,6.24,6.24,0,0,1-4.56,1.84,6.35,6.35,0,0,1-1.82-.26,5.89,5.89,0,0,1-1.52-.68,3.45,3.45,0,0,1-1-1,2,2,0,0,1-.37-1.15,2.34,2.34,0,0,1,.28-1.11,5,5,0,0,1,.68-1,4.74,4.74,0,0,1,.83-.74q.44-.31.72-.48l.69-.37-.55-.17a2.35,2.35,0,0,1-.59-.3,1.46,1.46,0,0,1-.47-.54,2,2,0,0,1-.18-.91,2.47,2.47,0,0,1,.39-1.37,3.52,3.52,0,0,1,1.33-1.1l.91-.49a3.83,3.83,0,0,1-.53-.8,2.57,2.57,0,0,1-.21-1.12,3.38,3.38,0,0,1,.47-1.7,5.41,5.41,0,0,1,1.21-1.45,6,6,0,0,1,1.63-1,4.37,4.37,0,0,1,1.72-.37,4.13,4.13,0,0,1,1.12.14,3.33,3.33,0,0,1,.85.39,2.88,2.88,0,0,1,.62.5,3.9,3.9,0,0,1,.39.52,5.73,5.73,0,0,1,.72-.87,1.56,1.56,0,0,1,1.18-.44,1.58,1.58,0,0,1,1.12.45,1.4,1.4,0,0,1,.48,1.08,1.09,1.09,0,0,1-.24.73,1,1,0,0,1-.82.28.93.93,0,0,1-.58-.17.54.54,0,0,1-.23-.44.68.68,0,0,1,.07-.35,1,1,0,0,1,.18-.22,1.13,1.13,0,0,0,.17-.24.78.78,0,0,0,.07-.38.37.37,0,0,0-.12-.27.48.48,0,0,0-.35-.12,1.09,1.09,0,0,0-.77.37,3.11,3.11,0,0,0-.66.93,1.85,1.85,0,0,1,.26.58,2.37,2.37,0,0,1,.11.71,3,3,0,0,1-.45,1.55,5.69,5.69,0,0,1-1.18,1.38,6.6,6.6,0,0,1-1.59,1,4.38,4.38,0,0,1-1.68.36,4.2,4.2,0,0,1-1.74-.28,10.14,10.14,0,0,1-.92-.48l-.52.25a3.7,3.7,0,0,0-1.2.82,1.39,1.39,0,0,0-.4.9.85.85,0,0,0,.53.8,4.65,4.65,0,0,0,1.71.34l3.16.14A4.64,4.64,0,0,1,269.79,182.12Zm-8,2.5a3.82,3.82,0,0,0-.34,1.72,2.93,2.93,0,0,0,.26,1.32,2.55,2.55,0,0,0,1.67,1.36,4.26,4.26,0,0,0,1.12.15,5.6,5.6,0,0,0,2-.36,6.19,6.19,0,0,0,1.68-.92,4.42,4.42,0,0,0,1.14-1.31,3,3,0,0,0,.42-1.52,2.13,2.13,0,0,0-.13-.76,1.73,1.73,0,0,0-.46-.64,2.4,2.4,0,0,0-.86-.46A4.21,4.21,0,0,0,267,183l-3.48-.13-.69.55A3.82,3.82,0,0,0,261.77,184.62Zm3.58-6.45a2.16,2.16,0,0,0,1.45.43,2.19,2.19,0,0,0,1.42-.49,4.09,4.09,0,0,0,1-1.22,5.64,5.64,0,0,0,.58-1.58,7.06,7.06,0,0,0,.19-1.57,1.77,1.77,0,0,0-.56-1.5,2.18,2.18,0,0,0-1.36-.42,2,2,0,0,0-1.36.51,4.67,4.67,0,0,0-1,1.22,6.11,6.11,0,0,0-.64,1.53,5.44,5.44,0,0,0-.22,1.45A2.06,2.06,0,0,0,265.35,178.17Z"/>
                </g>
                <polygon className="cls-1"
                         points="33.31 109.29 19.14 138.71 4.97 109.29 0 109.29 17.71 146 20.57 146 38.34 109.29 33.31 109.29 33.31 109.29"/>
                <polygon className="cls-1"
                         points="49.58 109.08 54.22 109.08 54.22 146.14 49.58 146.14 49.58 109.08 49.58 109.08"/>
                <polygon className="cls-1"
                         points="71.38 109.29 75.94 109.29 75.94 141.78 99.92 141.78 99.92 146 71.38 146 71.38 109.29 71.38 109.29"/>
                <polygon className="cls-1"
                         points="111.56 109.29 116.19 109.29 116.19 123.8 137.85 123.8 137.85 109.29 142.41 109.29 142.41 146 137.85 146 137.85 128.02 116.19 128.02 116.19 146 111.56 146 111.56 109.29 111.56 109.29"/>
                <polygon className="cls-1"
                         points="159.65 109.29 188.52 109.29 188.52 113.51 164.21 113.51 164.21 124.27 178.92 124.27 178.92 128.5 164.21 128.5 164.21 141.78 190.02 141.78 190.02 146 159.65 146 159.65 109.29 159.65 109.29"/>
                <polygon className="cls-1"
                         points="202.96 109.29 207.52 109.29 207.52 141.78 231.5 141.78 231.5 146 202.96 146 202.96 109.29 202.96 109.29"/>
                <polygon className="cls-1"
                         points="242.94 109.29 247.16 109.29 262.01 131.77 276.93 109.29 281.08 109.29 281.08 146 276.59 146 276.59 117.33 262.01 139.05 247.43 117.33 247.43 146 242.94 146 242.94 109.29 242.94 109.29"/>
                <polygon className="cls-1"
                         points="326.44 115.35 299.54 115.35 299.54 111.06 335.02 111.06 310.36 146 304.99 146 326.44 115.35 326.44 115.35"/>
                <polygon className="cls-1" points="1.28 109.29 0 109.29 1.28 111.94 1.28 109.29 1.28 109.29"/>
                <polygon className="cls-1"
                         points="335.02 111.06 333.74 111.06 333.74 112.87 335.02 111.06 335.02 111.06"/>
                <polygon className="cls-1"
                         points="209.1 0 204.34 0 171.09 68.91 137.83 0 133.07 0 171.07 78.75 171.1 78.75 209.1 0 209.1 0"/>
                <polygon className="cls-1"
                         points="194.67 0 171.13 48.79 147.5 0 142.74 0 171.09 58.73 199.44 0 194.67 0 194.67 0"/>
                <polygon className="cls-1"
                         points="154.5 3.9 183.5 3.9 168.86 34.24 171.24 39.17 190.14 0 152.71 0 154.5 3.9 154.5 3.9"/>
            </g>
        </g>
    </g>
</svg>


const IconWrapper = styled.div`
max-width:282px;
width:100%;
padding: 0 4rem;
     .cls-1 {
            fill: #231f20;
        }
`
const SliderWrap = styled.div`
padding: 1rem 16px;
max-width: calc(1144px + 2 * 16px);
width: 100%;
padding: 0;
justify-self: center;
//max-width: calc(1144px + 2 * 16px);
margin:auto;
display:flex;
//padding-top:3.3rem;

   @media (max-width: ${constants.breakpoints.lg}) {
padding-top:0px;

 }

.slick-slider{
width:100%;
}
`

const Slide = styled.div`

color:white;
display:flex !important;
justify-content:center;
align-items:center;
padding:0;

.gatsby-image-wrapper .gatsby-image-wrapper-constrained {
width:50%;

}
   
`

const SlideContent = styled.div`
`

const Me7aType = styled.a`
cursor:hover;
text-decoration:none;
color:rgb(68,68,68);
font-family:'Madera';
padding: 0 0rem;
display:flex;
align-items:center;

width:100%;
justify-content:center;
font-size:1rem;
//letter-spacing:24px;

`

const Caption = styled.div`
font-family:"Madera Thin";
font-size:1.4rem;
color:black;
text-align:center;
letter-spacing:0px;
padding-bottom:1rem;
`

const ThinLine = styled.div`
width:22px;
height:2px;
background:var(--color-primary);
margin:3rem auto 2rem auto;

`

const Arrow = styled.div`
padding-right:11px;
padding-top:4px;
font-size:1.7rem;
color:rgba(68,68,68);
`





export default class CoworkingSlider extends React.Component {
  render() {

    return (
        <SliderWrap>
          <Swiper
              spaceBetween={50}
              slidesPerView={1}
          >
            <SwiperSlide>
            <SlideContent>
                <Slide>
                    <IconWrapper>
             <V7CoworkingIcon/>
                    </IconWrapper>
            </Slide>
                <ThinLine/>
                <Caption><FormattedMessage id={'brands_3'}/></Caption>

                <Me7aType  target="_blank" rel="noopener noreferrer" href={'https://www.vilhelm7coworking.de/'}>
                    <Arrow><BsBoxArrowInRight/></Arrow>
                    <Typist avgTypingDelay={170}>
                        <span>vilhelm7coworking.de</span>
                        <Typist.Backspace count={2} delay={900} />
                        <span>com</span>
                    </Typist>
                </Me7aType>

            </SlideContent>
            </SwiperSlide>
          </Swiper>
        </SliderWrap>
    );
  }
}
