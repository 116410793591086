
import React from "react";
import styled from "styled-components";
//import {FormattedMessage } from "gatsby-plugin-react-intl";
//import {Me7averseImg1} from "../../../images/Image";

import { Swiper, SwiperSlide } from 'swiper/react';
import {constants} from "../../../theme";
import 'react-typist/dist/Typist.css';
import Typist from "react-typist";
import {FormattedMessage} from "gatsby-plugin-react-intl";
import {BsBoxArrowInRight} from "react-icons/bs";


const IconWrapper = styled.div`
max-width:282px;
width:100%;
padding: 0 4rem;
    .cls-1 {
         fill: #231f20;
        }
`

const V7ColivingIcon = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.02 182.67">
    <g id="Layer_2" data-name="Layer 2">
        <g id="Ebene_1" data-name="Ebene 1">
            <g>
                <g>
                    <path className="cls-1"
                          d="M83.26,165.17l.09-.35H88.9a10.28,10.28,0,0,1,2.3.24,5.42,5.42,0,0,1,1.8.71,3.69,3.69,0,0,1,1.19,1.2,3.36,3.36,0,0,1,.08,3.14,3.91,3.91,0,0,1-.89,1.13,4.86,4.86,0,0,1-1.22.8,8.49,8.49,0,0,1-1.33.52,7.68,7.68,0,0,1-1.23.28,6.22,6.22,0,0,1-.92.09l-.05,0c.21.05.55.16,1,.32A6,6,0,0,1,91,174a4.62,4.62,0,0,1,1.23,1.26,3.22,3.22,0,0,1,.53,1.9,3.75,3.75,0,0,1-.72,2.27,6,6,0,0,1-1.9,1.65,9.34,9.34,0,0,1-2.64,1,13.27,13.27,0,0,1-2.92.33c-.61,0-1.2,0-1.79,0s-1.18,0-1.81,0H77.44l.1-.34H80l6.08-16.86Zm.12,16.92q.54,0,1.08,0a6,6,0,0,0,2.75-.58,6,6,0,0,0,1.9-1.44,5.66,5.66,0,0,0,1.11-1.91,6,6,0,0,0,.35-2,3.54,3.54,0,0,0-.14-1.08,2.84,2.84,0,0,0-.39-.8,3.09,3.09,0,0,0-.5-.57,2.42,2.42,0,0,0-.54-.36,3.11,3.11,0,0,0-1-.3,10.84,10.84,0,0,0-1.27-.07H85.47L82.29,182Zm4.53-9.38a4.84,4.84,0,0,0,1.77-.32A4,4,0,0,0,92,170a5.89,5.89,0,0,0,.32-2,2.83,2.83,0,0,0-.76-2,3,3,0,0,0-2.17-.77h-1l-2.71,7.54Z"/>
                    <path className="cls-1"
                          d="M103.37,180.58a4.53,4.53,0,0,1-.82.92,5.55,5.55,0,0,1-1.27.81,4.14,4.14,0,0,1-1.81.36,4.2,4.2,0,0,1-1.79-.35,3.42,3.42,0,0,1-1.84-2.08,4.17,4.17,0,0,1-.21-1.27,6.15,6.15,0,0,1,.6-2.55A9.61,9.61,0,0,1,97.81,174a9,9,0,0,1,2.2-1.81,5,5,0,0,1,2.52-.71,4.09,4.09,0,0,1,1,.11,3,3,0,0,1,.89.33,2,2,0,0,1,.64.57,1.37,1.37,0,0,1,.25.84,2,2,0,0,1-.36,1.17,3.45,3.45,0,0,1-.92.86,6.28,6.28,0,0,1-1.25.6,8.82,8.82,0,0,1-1.32.38c-.42.09-.81.16-1.17.2a7.64,7.64,0,0,1-.77.06l-.82,0a6,6,0,0,1-.69-.08,16.2,16.2,0,0,0-.45,1.57,6.7,6.7,0,0,0-.19,1.64,2.59,2.59,0,0,0,.69,2,2.44,2.44,0,0,0,1.75.66,3.06,3.06,0,0,0,1.39-.31,4.62,4.62,0,0,0,1.06-.73,5.43,5.43,0,0,0,.77-.85c.2-.29.37-.51.5-.68l.3.18A5.06,5.06,0,0,0,103.37,180.58Zm.09-8.41a1.21,1.21,0,0,0-1-.35,2.69,2.69,0,0,0-1.47.45,5.69,5.69,0,0,0-1.27,1.09,8.47,8.47,0,0,0-1,1.41,8,8,0,0,0-.64,1.39,6.81,6.81,0,0,0,1.25.1,5.19,5.19,0,0,0,1.68-.27,5.08,5.08,0,0,0,1.39-.72,3.6,3.6,0,0,0,1-1,2.37,2.37,0,0,0,.36-1.24A1.23,1.23,0,0,0,103.46,172.17Z"/>
                    <path className="cls-1"
                          d="M109.28,173.47l.39-1.33h-2.14l.1-.34h3.79c-.08.34-.2.78-.35,1.3s-.33,1-.51,1.53l0,.05c.16-.25.37-.54.62-.89a8.38,8.38,0,0,1,.83-1,5.08,5.08,0,0,1,1-.82,2.37,2.37,0,0,1,1.21-.33,1.78,1.78,0,0,1,1.39.52,1.53,1.53,0,0,1,.46,1.11,1.39,1.39,0,0,1-.14.65,1.09,1.09,0,0,1-.34.39,1.31,1.31,0,0,1-.44.19,2,2,0,0,1-.41.05.86.86,0,0,1-.57-.18.59.59,0,0,1-.22-.51.61.61,0,0,1,.11-.41c.07-.09.15-.17.23-.26a3.27,3.27,0,0,0,.24-.27.67.67,0,0,0,.11-.39.48.48,0,0,0-.2-.4A.79.79,0,0,0,114,172a1.86,1.86,0,0,0-1.09.48,7.48,7.48,0,0,0-1.22,1.23,12.7,12.7,0,0,0-1.14,1.71,11.77,11.77,0,0,0-.89,1.92l-1.78,5H106.2l2.66-7.59C109,174.35,109.15,173.92,109.28,173.47Z"/>
                    <path className="cls-1"
                          d="M118.35,180.15c-.08.25-.17.5-.25.75a2.47,2.47,0,0,0-.12.73.8.8,0,0,0,.17.51.64.64,0,0,0,.52.21,1.38,1.38,0,0,0,.78-.28,4.42,4.42,0,0,0,1.24-1.35,6.5,6.5,0,0,0,.32-.59l.3.12-.31.6a3.53,3.53,0,0,1-.58.82,3.61,3.61,0,0,1-.91.7,2.6,2.6,0,0,1-1.28.3,2.25,2.25,0,0,1-1.3-.34,1.26,1.26,0,0,1-.48-1.12,3.2,3.2,0,0,1,.15-.92q.15-.51.3-.93L122.39,164H120.2l.1-.35h3.91Z"/>
                    <path className="cls-1"
                          d="M124.09,172.14l.1-.34h3.92l-2.91,8.35c-.08.25-.16.5-.25.75a2.47,2.47,0,0,0-.12.73.8.8,0,0,0,.17.51.64.64,0,0,0,.52.21,1.38,1.38,0,0,0,.78-.28,4.57,4.57,0,0,0,.7-.62,5.08,5.08,0,0,0,.54-.73,4.68,4.68,0,0,0,.32-.59l.3.12-.31.6a3.53,3.53,0,0,1-.58.82,3.61,3.61,0,0,1-.91.7,2.6,2.6,0,0,1-1.28.3,2.25,2.25,0,0,1-1.3-.34,1.26,1.26,0,0,1-.48-1.12,3.2,3.2,0,0,1,.15-.92q.15-.51.3-.93l2.53-7.22Zm4.06-7a1.08,1.08,0,0,1,.82-.34,1.14,1.14,0,0,1,1.16,1.16,1.13,1.13,0,0,1-1.16,1.16,1.14,1.14,0,0,1-1.16-1.16A1.08,1.08,0,0,1,128.15,165.16Z"/>
                    <path className="cls-1"
                          d="M131,172.14l.1-.34H135l-1,3h0q2.4-3.18,4.51-3.18a2.32,2.32,0,0,1,1.64.55,1.81,1.81,0,0,1,.61,1.37,2.63,2.63,0,0,1-.14.83l-.33,1-1.6,4.56c-.1.28-.19.55-.28.83a2.77,2.77,0,0,0-.12.8,1.25,1.25,0,0,0,.12.54.55.55,0,0,0,.52.25,1.44,1.44,0,0,0,.81-.25,3.47,3.47,0,0,0,.68-.59,4.11,4.11,0,0,0,.53-.73,6.33,6.33,0,0,0,.35-.65l.27.12c-.08.16-.2.38-.35.65a4.08,4.08,0,0,1-.58.81,3.3,3.3,0,0,1-.87.67,2.54,2.54,0,0,1-1.23.29,2.42,2.42,0,0,1-1.33-.35,1.25,1.25,0,0,1-.55-1.13,2.63,2.63,0,0,1,.09-.67c.06-.23.13-.47.21-.74l2.07-5.89a3.27,3.27,0,0,0,.2-1,1.05,1.05,0,0,0-.21-.62.85.85,0,0,0-.75-.29,2.61,2.61,0,0,0-1.26.4,6.93,6.93,0,0,0-1.43,1.11,10.23,10.23,0,0,0-1.34,1.65,9.68,9.68,0,0,0-1,2.06l-1.84,5.15h-1.7l3.54-10.23Z"/>
                    <path className="cls-1"
                          d="M152.63,165.17l.15-.35h6a8.59,8.59,0,0,1,1.78.2,5.39,5.39,0,0,1,1.68.65,4,4,0,0,1,1.24,1.19,3.37,3.37,0,0,1,.11,3.34,3.87,3.87,0,0,1-1,1.12,5.8,5.8,0,0,1-1.31.75,10.32,10.32,0,0,1-1.38.48,9.74,9.74,0,0,1-1.2.26l-.77.1,0,.07a8.43,8.43,0,0,1,1,.2,4.4,4.4,0,0,1,1.2.49,3.46,3.46,0,0,1,1,.91,2.4,2.4,0,0,1,.42,1.46,7.64,7.64,0,0,1-.1,1.19q-.1.59-.21,1.14c-.08.37-.15.74-.21,1.12a6.48,6.48,0,0,0-.1,1.18,1.56,1.56,0,0,0,.37,1.1,1.22,1.22,0,0,0,.94.4l.4,0a1.51,1.51,0,0,0,.31-.09l.1.32-.48.07a4.4,4.4,0,0,1-.73.05,5.19,5.19,0,0,1-1.22-.13,2.62,2.62,0,0,1-1-.45,1.94,1.94,0,0,1-.65-.81,2.89,2.89,0,0,1-.24-1.24,6.56,6.56,0,0,1,.09-1c.06-.4.12-.82.21-1.26l.18-1.28a8.2,8.2,0,0,0,.09-1.11,2.66,2.66,0,0,0-.14-.85,1.49,1.49,0,0,0-.5-.7,2.65,2.65,0,0,0-1-.47,6.76,6.76,0,0,0-1.72-.18h-1l-3.23,9h2.83l-.12.34h-7.57l.13-.34h2.51l6-16.86Zm4,7.54a8.37,8.37,0,0,0,1.75-.17,3.83,3.83,0,0,0,1.18-.45,4.23,4.23,0,0,0,.71-.56,3.87,3.87,0,0,0,.67-.85,5,5,0,0,0,.49-1.19,5.75,5.75,0,0,0,.2-1.59,2.47,2.47,0,0,0-.84-2.05,3.15,3.15,0,0,0-2.07-.68h-1L155,172.71Z"/>
                    <path className="cls-1"
                          d="M172.71,180.58a4.18,4.18,0,0,1-.81.92,5.55,5.55,0,0,1-1.27.81,4.74,4.74,0,0,1-3.6,0,3.42,3.42,0,0,1-1.84-2.08A4.17,4.17,0,0,1,165,179a6.15,6.15,0,0,1,.6-2.55,9.61,9.61,0,0,1,1.58-2.42,9,9,0,0,1,2.2-1.81,5,5,0,0,1,2.52-.71,4.09,4.09,0,0,1,1,.11,3,3,0,0,1,.89.33,2,2,0,0,1,.64.57,1.44,1.44,0,0,1,.25.84,2,2,0,0,1-.36,1.17,3.45,3.45,0,0,1-.92.86,6.28,6.28,0,0,1-1.25.6,8.82,8.82,0,0,1-1.32.38c-.42.09-.82.16-1.17.2a7.64,7.64,0,0,1-.77.06l-.82,0a6,6,0,0,1-.69-.08,16.2,16.2,0,0,0-.45,1.57,6.7,6.7,0,0,0-.19,1.64,2.59,2.59,0,0,0,.69,2,2.42,2.42,0,0,0,1.75.66,3.06,3.06,0,0,0,1.39-.31,4.62,4.62,0,0,0,1.06-.73,5.43,5.43,0,0,0,.77-.85c.2-.29.37-.51.5-.68l.3.18A6.11,6.11,0,0,0,172.71,180.58Zm.1-8.41a1.21,1.21,0,0,0-1-.35,2.69,2.69,0,0,0-1.47.45,5.69,5.69,0,0,0-1.27,1.09,8.47,8.47,0,0,0-1,1.41,8,8,0,0,0-.64,1.39,6.81,6.81,0,0,0,1.25.1,5.19,5.19,0,0,0,1.68-.27,5.08,5.08,0,0,0,1.39-.72,3.6,3.6,0,0,0,1-1,2.37,2.37,0,0,0,.36-1.24A1.23,1.23,0,0,0,172.81,172.17Z"/>
                    <path className="cls-1"
                          d="M177.31,175.93a2.15,2.15,0,0,1-.31-1.15,2.57,2.57,0,0,1,.3-1.16,3.93,3.93,0,0,1,.81-1.07,4.14,4.14,0,0,1,1.25-.78,4.2,4.2,0,0,1,1.56-.29,2.77,2.77,0,0,1,1.66.45,1.4,1.4,0,0,1,.63,1.17.9.9,0,0,1-1,1,1,1,0,0,1-.6-.2.68.68,0,0,1-.26-.57.67.67,0,0,1,.16-.5.54.54,0,0,0,.16-.41c0-.42-.28-.64-.84-.64a2.66,2.66,0,0,0-1,.18,1.83,1.83,0,0,0-1.13,1.13,2.11,2.11,0,0,0-.14.74,1.77,1.77,0,0,0,.25.92,8.37,8.37,0,0,0,.69,1l1.45,1.9a2.26,2.26,0,0,1,.41.78,3.3,3.3,0,0,1,.11.82,2.59,2.59,0,0,1-.42,1.47,3.75,3.75,0,0,1-1.05,1.05,4.89,4.89,0,0,1-1.35.63,5,5,0,0,1-1.37.21,4.7,4.7,0,0,1-1.08-.13,3.53,3.53,0,0,1-.93-.35,2,2,0,0,1-.65-.6,1.35,1.35,0,0,1-.25-.82,1,1,0,0,1,.36-.85,1.41,1.41,0,0,1,.9-.29.61.61,0,0,1,.4.16.49.49,0,0,1,.19.39,1.3,1.3,0,0,1-.12.55,1.18,1.18,0,0,0-.13.56.87.87,0,0,0,.39.82,1.81,1.81,0,0,0,1,.24,2.85,2.85,0,0,0,1.91-.69,2,2,0,0,0,.51-.67,1.74,1.74,0,0,0,.17-.69,2.31,2.31,0,0,0-.16-.89,3.94,3.94,0,0,0-.53-.88L178,176.9C177.74,176.57,177.52,176.25,177.31,175.93Z"/>
                    <path className="cls-1"
                          d="M185.23,172.14l.1-.34h3.92l-2.91,8.35-.24.75a2.21,2.21,0,0,0-.13.73.76.76,0,0,0,.18.51.61.61,0,0,0,.51.21,1.35,1.35,0,0,0,.78-.28,4.57,4.57,0,0,0,.7-.62,4.43,4.43,0,0,0,.54-.73,4.68,4.68,0,0,0,.32-.59l.3.12-.31.6a3.53,3.53,0,0,1-.58.82,3.44,3.44,0,0,1-.91.7,2.6,2.6,0,0,1-1.28.3,2.24,2.24,0,0,1-1.29-.34,1.26,1.26,0,0,1-.48-1.12,3.17,3.17,0,0,1,.14-.92q.15-.51.3-.93l2.54-7.22Zm4.06-7a1.08,1.08,0,0,1,.82-.34,1.14,1.14,0,0,1,1.16,1.16,1.15,1.15,0,0,1-.33.83,1.13,1.13,0,0,1-.83.33,1.11,1.11,0,0,1-.82-.33A1.15,1.15,0,0,1,189,166,1.11,1.11,0,0,1,189.29,165.16Z"/>
                    <path className="cls-1"
                          d="M199.52,180.5a4,4,0,0,0-.16,1.06c0,.42.2.64.61.64a1.48,1.48,0,0,0,.89-.29,3.19,3.19,0,0,0,.68-.64,4.28,4.28,0,0,0,.46-.71c.13-.24.21-.41.26-.51l.32.18a6.28,6.28,0,0,1-.37.69,3.57,3.57,0,0,1-.64.8,4.17,4.17,0,0,1-.91.66,2.45,2.45,0,0,1-1.2.29c-1.09,0-1.63-.5-1.63-1.48a4,4,0,0,1,.07-.61,5.07,5.07,0,0,1,.2-.77h0c-.23.28-.49.58-.79.92a7.46,7.46,0,0,1-1,.94,5.27,5.27,0,0,1-1.22.71,3.69,3.69,0,0,1-1.48.29,2.81,2.81,0,0,1-1-.19,2.2,2.2,0,0,1-.85-.57,2.59,2.59,0,0,1-.58-1,4.17,4.17,0,0,1-.21-1.4,5.37,5.37,0,0,1,.39-2,9.3,9.3,0,0,1,1-1.88A10.4,10.4,0,0,1,193.8,174a14.21,14.21,0,0,1,1.67-1.34,9.06,9.06,0,0,1,1.68-.88,4,4,0,0,1,1.47-.32,1.91,1.91,0,0,1,1.25.42,1.33,1.33,0,0,1,.52,1.1h.05l3.2-9h-2.17l.1-.35h3.92l-5.59,15.62C199.75,179.69,199.62,180.1,199.52,180.5Zm.56-6.6a2.22,2.22,0,0,0,.09-.57,2.25,2.25,0,0,0-.06-.49,1.33,1.33,0,0,0-.23-.48,1.56,1.56,0,0,0-.42-.38,1.5,1.5,0,0,0-.72-.16,3.06,3.06,0,0,0-1.59.46,6.16,6.16,0,0,0-1.45,1.19,10.42,10.42,0,0,0-1.25,1.69,14.41,14.41,0,0,0-1,1.92,13.08,13.08,0,0,0-.63,1.89,6.77,6.77,0,0,0-.22,1.6,2.33,2.33,0,0,0,.31,1.28,1.1,1.1,0,0,0,1,.5,2.78,2.78,0,0,0,1.45-.45,6.64,6.64,0,0,0,1.36-1.06,10.55,10.55,0,0,0,1.06-1.23,4.42,4.42,0,0,0,.56-1L199.7,175A9.74,9.74,0,0,0,200.08,173.9Z"/>
                    <path className="cls-1"
                          d="M212,180.58a4.45,4.45,0,0,1-.81.92,5.55,5.55,0,0,1-1.27.81,4.74,4.74,0,0,1-3.6,0,3.47,3.47,0,0,1-1.18-.89,3.61,3.61,0,0,1-.67-1.19,4.17,4.17,0,0,1-.21-1.27,6.17,6.17,0,0,1,.61-2.55,9.61,9.61,0,0,1,1.58-2.42,8.86,8.86,0,0,1,2.2-1.81,5,5,0,0,1,2.52-.71,4.15,4.15,0,0,1,1,.11,3,3,0,0,1,.89.33,2.07,2.07,0,0,1,.64.57,1.44,1.44,0,0,1,.25.84,2,2,0,0,1-.36,1.17,3.35,3.35,0,0,1-.93.86,5.94,5.94,0,0,1-1.24.6,8.82,8.82,0,0,1-1.32.38c-.43.09-.82.16-1.17.2a7.59,7.59,0,0,1-.78.06l-.81,0a6,6,0,0,1-.69-.08c-.18.53-.33,1.05-.46,1.57a7.23,7.23,0,0,0-.18,1.64,2.59,2.59,0,0,0,.69,2,2.42,2.42,0,0,0,1.75.66,3.06,3.06,0,0,0,1.39-.31,4.85,4.85,0,0,0,1.06-.73,5.43,5.43,0,0,0,.77-.85c.2-.29.37-.51.5-.68l.3.18A6.11,6.11,0,0,0,212,180.58Zm.1-8.41a1.21,1.21,0,0,0-1-.35,2.69,2.69,0,0,0-1.47.45,5.69,5.69,0,0,0-1.27,1.09,8.53,8.53,0,0,0-1,1.41,8.78,8.78,0,0,0-.64,1.39,7,7,0,0,0,1.26.1,5.19,5.19,0,0,0,1.68-.27,5.08,5.08,0,0,0,1.39-.72,3.6,3.6,0,0,0,1-1,2.37,2.37,0,0,0,.36-1.24A1.23,1.23,0,0,0,212.07,172.17Z"/>
                    <path className="cls-1"
                          d="M215.92,172.14l.09-.34h3.9l-1,3h0q2.38-3.18,4.51-3.18a2.34,2.34,0,0,1,1.64.55,1.8,1.8,0,0,1,.6,1.37,2.63,2.63,0,0,1-.14.83l-.33,1-1.6,4.56c-.1.28-.19.55-.27.83a2.77,2.77,0,0,0-.12.8,1.25,1.25,0,0,0,.12.54.53.53,0,0,0,.52.25,1.49,1.49,0,0,0,.81-.25,3.79,3.79,0,0,0,.68-.59,4.68,4.68,0,0,0,.53-.73c.14-.25.26-.47.34-.65l.27.12c-.08.16-.19.38-.34.65a4.53,4.53,0,0,1-.58.81,3.35,3.35,0,0,1-.88.67,2.49,2.49,0,0,1-1.23.29,2.44,2.44,0,0,1-1.33-.35,1.24,1.24,0,0,1-.54-1.13,2.63,2.63,0,0,1,.09-.67c.05-.23.12-.47.2-.74l2.07-5.89a3,3,0,0,0,.2-1,1.05,1.05,0,0,0-.21-.62.85.85,0,0,0-.75-.29,2.65,2.65,0,0,0-1.26.4,7.2,7.2,0,0,0-1.43,1.11,10.8,10.8,0,0,0-1.34,1.65,9.2,9.2,0,0,0-1,2.06l-1.85,5.15h-1.7l3.55-10.23Z"/>
                    <path className="cls-1"
                          d="M235.69,180.9a5.56,5.56,0,0,1-.93.88,5,5,0,0,1-1.22.64,4.73,4.73,0,0,1-1.56.25,4.14,4.14,0,0,1-1.62-.3,3.41,3.41,0,0,1-1.2-.8,3.75,3.75,0,0,1-.74-1.17,4,4,0,0,1-.26-1.43,6.21,6.21,0,0,1,.6-2.58,9.26,9.26,0,0,1,1.57-2.41,8.71,8.71,0,0,1,2.26-1.8,5.31,5.31,0,0,1,2.62-.7,3.31,3.31,0,0,1,.83.1,2.09,2.09,0,0,1,.73.32,1.61,1.61,0,0,1,.52.6,1.94,1.94,0,0,1,.21.95,1.16,1.16,0,0,1-.4,1.06,1.66,1.66,0,0,1-.85.24.85.85,0,0,1-.62-.18.63.63,0,0,1-.17-.43,1,1,0,0,1,.1-.48,1.3,1.3,0,0,1,.23-.33A1.72,1.72,0,0,0,236,173a1.08,1.08,0,0,0,.09-.48.55.55,0,0,0-.28-.54,1.67,1.67,0,0,0-.77-.15,2.86,2.86,0,0,0-1.48.4,5,5,0,0,0-1.27,1,8,8,0,0,0-1,1.49,14,14,0,0,0-.76,1.73,12.06,12.06,0,0,0-.48,1.75,8.38,8.38,0,0,0-.16,1.58,2.79,2.79,0,0,0,.6,1.8,2.29,2.29,0,0,0,1.86.74,3.1,3.1,0,0,0,1.27-.26,4.34,4.34,0,0,0,1-.66,5,5,0,0,0,.81-.85c.23-.3.42-.58.57-.82l.35.2A9.28,9.28,0,0,1,235.69,180.9Z"/>
                    <path className="cls-1"
                          d="M245.74,180.58a4.53,4.53,0,0,1-.82.92,5.55,5.55,0,0,1-1.27.81,4.72,4.72,0,0,1-3.59,0,3.51,3.51,0,0,1-1.19-.89,3.58,3.58,0,0,1-.66-1.19A4.17,4.17,0,0,1,238,179a6.15,6.15,0,0,1,.6-2.55,9.61,9.61,0,0,1,1.58-2.42,9,9,0,0,1,2.2-1.81,5,5,0,0,1,2.52-.71,4.09,4.09,0,0,1,1,.11,3,3,0,0,1,.89.33,2,2,0,0,1,.64.57,1.37,1.37,0,0,1,.25.84,2,2,0,0,1-.36,1.17,3.45,3.45,0,0,1-.92.86,6.28,6.28,0,0,1-1.25.6,8.82,8.82,0,0,1-1.32.38c-.42.09-.81.16-1.17.2a7.64,7.64,0,0,1-.77.06l-.82,0a6,6,0,0,1-.69-.08,16.2,16.2,0,0,0-.45,1.57,6.7,6.7,0,0,0-.19,1.64,2.59,2.59,0,0,0,.69,2,2.44,2.44,0,0,0,1.75.66,3.06,3.06,0,0,0,1.39-.31,4.62,4.62,0,0,0,1.06-.73,5.43,5.43,0,0,0,.77-.85c.2-.29.37-.51.5-.68l.3.18A5.06,5.06,0,0,0,245.74,180.58Zm.09-8.41a1.21,1.21,0,0,0-1-.35,2.69,2.69,0,0,0-1.47.45,5.69,5.69,0,0,0-1.27,1.09,9.11,9.11,0,0,0-1,1.41,8,8,0,0,0-.64,1.39,6.81,6.81,0,0,0,1.25.1,5.19,5.19,0,0,0,1.68-.27,4.94,4.94,0,0,0,1.39-.72,3.6,3.6,0,0,0,1-1,2.37,2.37,0,0,0,.36-1.24A1.23,1.23,0,0,0,245.83,172.17Z"/>
                    <path className="cls-1"
                          d="M250.33,175.93a2.15,2.15,0,0,1-.31-1.15,2.57,2.57,0,0,1,.3-1.16,3.93,3.93,0,0,1,.81-1.07,4.14,4.14,0,0,1,1.25-.78,4.2,4.2,0,0,1,1.56-.29,2.82,2.82,0,0,1,1.67.45,1.4,1.4,0,0,1,.63,1.17.91.91,0,0,1-1,1,1,1,0,0,1-.6-.2.68.68,0,0,1-.26-.57.71.71,0,0,1,.16-.5.54.54,0,0,0,.16-.41c0-.42-.28-.64-.84-.64a2.66,2.66,0,0,0-1,.18,2,2,0,0,0-.71.46,1.89,1.89,0,0,0-.42.67,2.11,2.11,0,0,0-.14.74,1.77,1.77,0,0,0,.25.92,8.37,8.37,0,0,0,.69,1l1.45,1.9a2.1,2.1,0,0,1,.41.78,3.3,3.3,0,0,1,.11.82,2.59,2.59,0,0,1-.42,1.47,3.75,3.75,0,0,1-1,1.05,4.76,4.76,0,0,1-1.35.63,5,5,0,0,1-1.37.21,4.7,4.7,0,0,1-1.08-.13,3.53,3.53,0,0,1-.93-.35,2,2,0,0,1-.65-.6,1.42,1.42,0,0,1-.25-.82,1,1,0,0,1,.36-.85,1.41,1.41,0,0,1,.9-.29.66.66,0,0,1,.41.16.51.51,0,0,1,.18.39,1.3,1.3,0,0,1-.12.55,1.32,1.32,0,0,0-.13.56.87.87,0,0,0,.39.82,1.81,1.81,0,0,0,1,.24,2.88,2.88,0,0,0,1.1-.2,2.91,2.91,0,0,0,.81-.49,2,2,0,0,0,.51-.67,1.59,1.59,0,0,0,.17-.69,2.31,2.31,0,0,0-.16-.89,3.59,3.59,0,0,0-.53-.88L251,176.9C250.76,176.57,250.54,176.25,250.33,175.93Z"/>
                </g>
                <polygon className="cls-1"
                         points="33.31 109.29 19.14 138.71 4.97 109.29 0 109.29 17.71 146 20.57 146 38.34 109.29 33.31 109.29 33.31 109.29"/>
                <polygon className="cls-1"
                         points="49.58 109.08 54.22 109.08 54.22 146.14 49.58 146.14 49.58 109.08 49.58 109.08"/>
                <polygon className="cls-1"
                         points="71.38 109.29 75.94 109.29 75.94 141.78 99.92 141.78 99.92 146 71.38 146 71.38 109.29 71.38 109.29"/>
                <polygon className="cls-1"
                         points="111.56 109.29 116.19 109.29 116.19 123.8 137.85 123.8 137.85 109.29 142.41 109.29 142.41 146 137.85 146 137.85 128.02 116.19 128.02 116.19 146 111.56 146 111.56 109.29 111.56 109.29"/>
                <polygon className="cls-1"
                         points="159.65 109.29 188.52 109.29 188.52 113.51 164.21 113.51 164.21 124.27 178.92 124.27 178.92 128.5 164.21 128.5 164.21 141.78 190.02 141.78 190.02 146 159.65 146 159.65 109.29 159.65 109.29"/>
                <polygon className="cls-1"
                         points="202.96 109.29 207.52 109.29 207.52 141.78 231.5 141.78 231.5 146 202.96 146 202.96 109.29 202.96 109.29"/>
                <polygon className="cls-1"
                         points="242.94 109.29 247.16 109.29 262.01 131.77 276.93 109.29 281.08 109.29 281.08 146 276.59 146 276.59 117.33 262.01 139.05 247.43 117.33 247.43 146 242.94 146 242.94 109.29 242.94 109.29"/>
                <polygon className="cls-1"
                         points="326.44 115.35 299.54 115.35 299.54 111.06 335.02 111.06 310.36 146 304.99 146 326.44 115.35 326.44 115.35"/>
                <polygon className="cls-1" points="1.28 109.29 0 109.29 1.28 111.94 1.28 109.29 1.28 109.29"/>
                <polygon className="cls-1"
                         points="335.02 111.06 333.74 111.06 333.74 112.87 335.02 111.06 335.02 111.06"/>
                <polygon className="cls-1"
                         points="209.1 0 204.34 0 171.09 68.91 137.83 0 133.07 0 171.07 78.75 171.1 78.75 209.1 0 209.1 0"/>
                <polygon className="cls-1"
                         points="194.67 0 171.13 48.79 147.5 0 142.74 0 171.09 58.73 199.44 0 194.67 0 194.67 0"/>
                <polygon className="cls-1"
                         points="154.5 3.9 183.5 3.9 168.86 34.24 171.24 39.17 190.14 0 152.71 0 154.5 3.9 154.5 3.9"/>
            </g>
        </g>
    </g>
</svg>


const SliderWrap = styled.div`
   padding: 1rem 16px;
  max-width: calc(1144px + 2 * 16px);
 width: 100%;
 
padding: 0;
justify-self: center;
//max-width: calc(1144px + 2 * 16px);
margin:auto;
display:flex;
//padding-top:3.3rem;

   @media (max-width: ${constants.breakpoints.lg}) {
padding-top:0px;

 }

.slick-slider{
width:100%;
}
`

const Slide = styled.div`

color:white;
display:flex !important;
justify-content:center;
align-items:center;
padding:0;

.gatsby-image-wrapper .gatsby-image-wrapper-constrained {
width:50%;

}
   
`

const SlideContent = styled.div`
`


const Me7aType = styled.a`
cursor:hover;
text-decoration:none;
color:rgb(68,68,68);
font-family:'Madera';
padding: 0 0rem;
display:flex;
align-items:center;

width:100%;
justify-content:center;
font-size:1rem;
//letter-spacing:24px;

`

const Caption = styled.div`
font-family:"Madera Thin";
font-size:1.4rem;
color:black;
text-align:center;
letter-spacing:0px;
padding-bottom:1rem;
`

const ThinLine = styled.div`
width:22px;
height:2px;
background:var(--color-primary);
margin:3rem auto 2rem auto;

`

const Arrow = styled.div`
padding-right:11px;
padding-top:4px;
font-size:1.7rem;
color:rgba(68,68,68);
`




export default class ColivingSlider extends React.Component {
  render() {
    return (
        <SliderWrap>
          <Swiper
              spaceBetween={50}
              slidesPerView={1}
          >
            <SwiperSlide>
            <SlideContent>
                <Slide>
                    <IconWrapper>
                        <V7ColivingIcon/>
                    </IconWrapper>
            </Slide>
                <ThinLine/>
                <Caption><FormattedMessage id={'brands_2'}/></Caption>
                <Me7aType target="_blank" rel="noopener noreferrer" href={'https://www.vilhelm7.de/'}>
                    <Arrow><BsBoxArrowInRight/></Arrow>
                    <Typist avgTypingDelay={170}>
                        <span>wil</span>
                        <Typist.Backspace count={3} delay={900} />
                        <span>vilhelm7.de</span>
                    </Typist>
                </Me7aType>
            </SlideContent>
            </SwiperSlide>
          </Swiper>
        </SliderWrap>
    );
  }
}
